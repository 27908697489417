import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { formatDate } from "../../helpers";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { tabs } from "../../App";

const DetailsCard = ({
  selectedDate,
  selectedServices,
  selectedTime,
  selectedLanguage,
  bookingParams,
  bookingAmount,
  setBookingAmount,
  estTime,
  setEstTime,
  calculateTotalTime,
  calculateSubtotal,
  calculateTotal,
  cost1,
  cost2,
  selectedTab,
}) => {
 
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { t } = useTranslation();

  const numberOfCosts = bookingParams?.[0]?.extra_cost?.length;
  const minimumBookingTime = bookingParams?.[0]?.minimum_booking_time;
  const [colorr, setColor] = useState('transparent');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setColor('red'); 
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);
  const isEstimatedTimeValid = () => {
    if (selectedServices?.length === 0) {
      return true; // No services selected, so no need to show the alert
    }
    const estimatedTimeInMinutes = selectedServices?.reduce?.(
      (accumulator, service) =>
        accumulator +
        service.duration * service.quantities[0]?.service_quantity,
      0
    );

    return estimatedTimeInMinutes >= minimumBookingTime;
  };
  const addMinutesToTime = (time, minutes) => {
    const [hourStr, minuteStr] = time.split(":");
    const hour = parseInt(hourStr);
    const minute = parseInt(minuteStr);
    const totalMinutes = hour * 60 + minute + minutes;

    const newHour = Math.floor(totalMinutes / 60) % 12 || 12;
    const newMinute = totalMinutes % 60;
    const meridiem = totalMinutes < 720 ? "AM" : "PM";

    return `${newHour}:${String(newMinute).padStart(2, "0")} ${meridiem}`;
  };

  const timeRange = addMinutesToTime(selectedTime, estTime);
  return (
    <div
      style={{
        height: "200px !important",
        marginTop: "0px !important",
        position: "relative",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        // paddingLeft:"15px"
      }}
    >
      {isEstimatedTimeValid() ? null : (
        <Typography
          variant="body2"
          // color="gray"
          color={colorr}
          sx={{
            marginTop: "10px",
            fontWeight: "bold",
            textAlign: "center",
            alignSelf: "center",
            position: "absolute",
            top: "-32px",
            // paddingX: "15%",
          }}
        >
          {/* {t("Less than minimum booking time", {
            time: bookingParams?.[0]?.minimum_booking_time,
          })} */}

{t("minimumBooking")} { bookingParams?.[0]?.minimum_booking_time} {t("mins")}
        </Typography>
      )}
      <Card
        variant="outlined"
        // className="marginzero"
        className={selectedTab === "service" ? "marginzero" : "marginzero"}
        sx={{
          border: "none",
          borderRadius: "13px",
          padding: "10px 20px 20px 10px",
          width: isMobile ? "100%" : "290px",
          marginTop: { sm: "40px" },
          minHeight: "178px !important",
        }}
      >
        <Stack direction="column">
          {selectedDate ? (
            <Typography
              variant="subtitle2"
              color="rgba(8, 79, 140, 1)"
              sx={{
                fontSize: "15px",
                fontFamily: "Cairo",

                fontWeight: "700",
                lineHeight: "1",
              }}
            >
              {formatDate(selectedDate)} at {selectedTime}
            </Typography>
          ) : null}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontFamily: "Cairo",
                fontSize: "15px",
                // direction:
                //   selectedLanguage === "ar" ? "ltr !important" : "ltr important",
              }}
            >
              {t("estimatedTime")} : {""}
            </Typography>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{
                fontFamily: "Cairo",
                fontSize: "15px",
                direction:
                  selectedLanguage === "ar"
                    ? "ltr !important"
                    : "ltr important",
              }}
            >
              {""} {selectedServices?.length > 0 && calculateTotalTime()}
              {""}{" "}
            </Typography>
          </Box>
        </Stack>
{  (bookingParams?.[0]?.extra_cost?.[0].onOFF && bookingParams?.[0]?.extra_cost?.[1].onOFF) ||
(bookingParams?.[0]?.extra_cost?.[0].onOFF && !bookingParams?.[0]?.extra_cost?.[1].onOFF) ||
(!bookingParams?.[0]?.extra_cost?.[0].onOFF && bookingParams?.[0]?.extra_cost?.[1].onOFF) 
?
 <Stack
 direction="row"
 alignItems="center"
 justifyContent="space-between"
 marginTop="10px"
>
 <Typography
   variant="body2"
   sx={{
     fontSize: "15px",
     fontFamily: "Cairo",

     marginBottom: "0px",
   }}
   color="rgba(8, 79, 140, 1)"
   // gutterBottom
 >
   {t("subTotal")}
 </Typography>

 <Typography
   sx={{
     fontSize: "15px",
     fontFamily: "Cairo",

     marginBottom: "0px",
   }}
   color="rgba(0, 0, 0, 1)"
   // gutterBottom
 >
   OMR {calculateSubtotal()}
 </Typography>
</Stack>
:""
}
       
        {numberOfCosts > 0 && bookingParams?.[0]?.extra_cost?.[0].onOFF && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ textAlign: "center" }}
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: "15px",
                fontFamily: "Cairo",
                marginBottom: "0px",
              }}
              color="rgba(8, 79, 140, 1)"
            >
              {selectedLanguage === "ar"
                ? bookingParams?.[0]?.extra_cost?.[0]?.name_ar
                : bookingParams?.[0]?.extra_cost?.[0]?.name_en}{" "}
              {bookingParams?.[0]?.extra_cost?.[0]?.cost_type ===
                "Percentage" ||
              bookingParams?.[0]?.extra_cost?.[0]?.cost_type === "percentage"
                ? bookingParams?.[0]?.extra_cost?.[0]?.cost
                : ""}{" "}
              {bookingParams?.[0]?.extra_cost?.[0]?.cost_type ===
                "Percentage" ||
              bookingParams?.[0]?.extra_cost?.[0]?.cost_type === "percentage"
                ? "%"
                : ""}
            </Typography>

            <Typography
              sx={{
                fontSize: "15px",
                fontFamily: "Cairo",
                marginBottom: "0px",
              }}
              color="rgba(0, 0, 0, 1)"
            >
              OMR {cost1()}
            </Typography>
          </Stack>
        )}
        {numberOfCosts > 0 && bookingParams?.[0]?.extra_cost?.[1].onOFF && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: "15px",
                fontFamily: "Cairo",
                marginBottom: "0px",
              }}
              color="rgba(8, 79, 140, 1)"
            >
              {selectedLanguage === "ar"
                ? bookingParams?.[0]?.extra_cost?.[1]?.name_ar
                : bookingParams?.[0]?.extra_cost?.[1]?.name_en}{" "}
              {bookingParams?.[0]?.extra_cost?.[1]?.cost_type ===
                "percentage" ||
              bookingParams?.[0]?.extra_cost?.[1]?.cost_type === "Percentage"
                ? bookingParams?.[0]?.extra_cost?.[1]?.cost
                : ""}{" "}
              {bookingParams?.[0]?.extra_cost?.[1]?.cost_type ===
                "Percentage" ||
              bookingParams?.[0]?.extra_cost?.[1]?.cost_type === "percentage"
                ? "%"
                : ""}
            </Typography>

            <Typography
              sx={{
                fontSize: "15px",
                fontFamily: "Cairo",
                marginBottom: "0px",
              }}
              color="rgba(0, 0, 0, 1)"
            >
              OMR {cost2()}
            </Typography>
          </Stack>
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ marginTop: "10px" }}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "15px",
              fontFamily: "Cairo",

              marginBottom: "0px",
              fontWeight: "bold",
            }}
            color="rgba(8, 79, 140, 1)"
            // gutterBottom
          >
            {t("total")}
          </Typography>

          <Typography
            sx={{
              fontSize: "15px",
              fontFamily: "Cairo",

              marginBottom: "0px",
              fontWeight: "bold",
            }}
            color="rgba(0, 0, 0, 1)"
            // gutterBottom
          >
            OMR {calculateTotal()}
          </Typography>
        </Stack>
        <Stack></Stack>
      </Card>
    </div>
  );
};
export default DetailsCard;
