import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import assistance from "../../assets/assistance.svg";
import { useTranslation } from "react-i18next";

const Assistance = ({ selectedLanguage }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        sx={{
          padding:
            selectedLanguage === "ar" ? "0px 0% 0% 10%" : " 0px 10% 0% 7%",
          fontFamily: "Cairo",
        }}
      >
        <Stack>
          <div
            style={{
              width: "70px",
              height: "70px",
              borderRadius: "50%",
              overflow: "hidden",
              margin:
                selectedLanguage === "ar"
                  ? "40px 20px 20px 0px"
                  : "40px 40px 20px 0px",
            }}
          >
            <img
              src={assistance}
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </div>
          <Box sx={{ width: "196px", height: "auto" }}>
            <Typography
              variant="body2"
              className="assist1"
              sx={{
                fontSize: "10px",
                fontFamily: "Cairo",
                color: "#084F8C",
fontWeight:700,
marginBottom:'5px',
                lineHeight: "16px",
                letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
                // marginTop: "13px",
                // padding: "13px 0px 0px 0px",
              }}
            >
              {t("assistanceText")}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "600",
                color: "#084F8C",
                fontFamily: "Cairo",

                letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
              }}
            >
              {t("forAssistance")}
            </Typography>
            <Typography
              sx={{
                fontSize: "10px",
                fontFamily: "Cairo",

                color: "#084F8C",
                letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
              }}
            >
              {" "}
              Call +968 92123266
            </Typography>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Assistance;
