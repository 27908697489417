import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import Assistance from "./Assistance";
import "react-calendar/dist/Calendar.css";
import Timetags from "./Timetags";
import CommonButton from "../Common/CommonButton";
import { tabs } from "../../App";
// import { time } from "../../utils/data";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/system";
import Dropdown from "../Common/Select";
import { Button } from "@mui/base";
import 'intl';
import 'intl/locale-data/jsonp/ar';
import ar from 'date-fns/locale/ar';

import { registerLocale } from "date-fns"; // Import registerLocale from date-fns
import enUS from "date-fns/locale/en-US";

import {
  addDays,
  addMinutes,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isAfter,
  isSameMonth,
  isWithinInterval,
  parse,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";
import { isBefore, isSameDay } from "date-fns";
import moment, { months, weekdays } from "moment";

const DateTime = ({
  selectedDate,
  setSelectedTab,
  handleSelectedDate,
  setSelectedTime,
  selectedTime,
  selectedLanguage,
  options,
  getTimeSlots,
  timeSlots,
  selectedLocation,
  setSelectedLocation,
  selectedLocationId,
  setSelectedLocationId,
  selectedServices,
  bookingParams,
  getLocation,
  setSelectedTimeObj
  ,timeSlotData

}) => {

  const [timeSlot, setTimeSlots] = useState([]);


  useEffect(() => {
    sessionStorage.setItem("servicesss", JSON.stringify(selectedServices));
  }, [selectedServices]);
  useEffect(() => {
    getTimeSlots();

    
  }, [selectedLocationId, selectedDate]);


  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMobile1 = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { t } = useTranslation();

  const formatMonthYear = (locale, date) => {
    const options = { month: "long", year: "numeric" };
    return new Intl.DateTimeFormat(locale, options).format(date);
  };
  const formattedDate = moment(selectedDate).format("MMM D");
  const maxDate = addMonths(Date(), 1); // Set maximum date as one month from current date
  // const nextMonthEnd = endOfMonth(addMonths(new Date(), 1));
  const nextMonthEnd = endOfMonth(addMonths(new Date(), 1));
  const startOfMonthDate = startOfMonth(new Date());
  const currentDateTime = new Date();
  // const customLocale = {
  //   ...enLocale, // Start with the default English (United States) locale
  //   options: {
  //     ...enLocale.options,
  //     weekStartsOn: 5, // Set the week to start from Friday (index 5)
  //   },
  // };
  const tileClassName = ({ date }) => {
    const currentDate = new Date();
    const endOfNextMonthDate = endOfMonth(addMonths(currentDate, 1));

    if (
      (isAfter(date, currentDate) || isSameDay(date, currentDate)) &&
      isBefore(date, endOfNextMonthDate)
    ) {
      // Apply the "blue-dates" class for dates from current date to the end of next month
      return "blue-dates";
    }

    // All other dates will have the "gray-dates" class
    return "gray-dates";
  };
  const startOfWeekDate = startOfWeek(new Date(), { weekStartsOn: 1 }); // Assuming Monday is the start of the week
  const endOfWeekDate = endOfWeek(new Date(), { weekStartsOn: 1 });
  const isNextMonth = (date) => {
    const nextMonthStartDate = addMonths(new Date(), 1);
    const nextMonthEndDate = endOfMonth(nextMonthStartDate);
    return isAfter(date, nextMonthEndDate);
  };

  const timeSlotsArray = Array.isArray(timeSlots) ? timeSlots : [timeSlots];

  //////////////filter timeslots/////////////
  const [newCurrentTime, setNewCurrentTime] = useState("");
  const [currentSlots, setCurrentSlots] = useState([]);

  // const filterCurrentDateSlots = () => {
  //   if (selectedDate) {
  //     const currentDate = new Date();
  //     const currentYear = currentDate.getFullYear();
  //     const currentMonth = currentDate.getMonth() + 1;
  //     const currentDay = currentDate.getDate();
  //     const currentFormattedDate = `${currentYear}-${String(
  //       currentMonth
  //     ).padStart(2, "0")}-${String(currentDay).padStart(2, "0")}`;

  //     if (selectedDate === currentFormattedDate) {
  //       const bookingSlotIncrements =
  //         bookingParams?.[0]?.booking_slot_increments;
  //       const bookingTimeWindow = bookingParams?.[0]?.booking_time_window;
  //       const totalMinutes = bookingSlotIncrements * bookingTimeWindow;

  //       const currentTime = new Date();
  //       const newTime = addMinutes(currentTime, totalMinutes);
  //       const formattedTime = format(newTime, "HH:mm");

  //       setNewCurrentTime(formattedTime);

  //       const filteredSlots = timeSlotsArray.filter((slot) => {
  //         // Try parsing the slot as 12-hour format
  //         const parsedSlot = parse(slot, "h:mm a", new Date());

  //         // Check if the parsed slot is valid
  //         if (isNaN(parsedSlot)) {
  //           console.error(`Invalid time value: ${slot}`);
  //           return false; // Skip invalid slots during filtering
  //         }

  //         // Convert the parsed slot to 24-hour format
  //         const formattedSlotTime = format(parsedSlot, "HH:mm");

  //         // Compare the slot time with newCurrentTime
  //         return formattedSlotTime > formattedTime;
  //       });

  //       setCurrentSlots(filteredSlots);

  //       if (filteredSlots.length === 0) {
  //         console.log("No time slots available after the current time.");
  //       }
  //     } else {
  //       setCurrentSlots(timeSlotsArray);
  //     }
  //   }
  // };
  // useEffect(() => {
  //   if (timeSlots) {
  //     filterCurrentDateSlots();
  //   }
  // }, [timeSlots, selectedDate]);

  // useEffect(() => {
  //   filterCurrentDateSlots();
  // }, [selectedDate]);

  return (
    <Grid
      container
      spacing={2}
      className="calendar-grid-item"
      // padding="0px 3px !important"
      display="flex"
    >
      {!isMobile && (
        <Grid
          item
          sx={{
            paddingLeft: selectedLanguage === "ar" ? "0px !important" : "",
          }}
        >
          <Stack
            direction="column"
            // sx={{ marginTop: { sm: "15px" } }}

            sx={{
              paddingRight: selectedLanguage === "ar" ? "10px" : "0px",
              paddingLeft:
                selectedLanguage === "ar" ? "0px !important" : "0px !important",
            }}
          >
            <Box>
              <div
                className={
                  selectedLanguage === "ar" ? "expand-icon1" : "expand-icon"
                }
              >
                <Dropdown
                  width={"100%"}
                  options={options}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  selectedLocationId={selectedLocationId}
                  setSelectedLocationId={setSelectedLocationId}
                />
              </div>
            </Box>

            <Assistance selectedLanguage={selectedLanguage} />
          </Stack>
        </Grid>
      )}
      {isMobile && (
        <Grid item xs={12} sm={3} md={3}>
          <Stack direction="column">
            <div
              className={
                selectedLanguage === "ar" ? "expand-icon1" : "expand-icon"
              }
            >
              {/* <Dropdown
                width={"100%"}
                selectedLanguage={selectedLanguage}
                options={options}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                selectedLocationId={selectedLocationId}
                setSelectedLocationId={setSelectedLocationId}
              /> */}

              <Dropdown
                width={"100%"}
                options={options}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                selectedLocationId={selectedLocationId}
                setSelectedLocationId={setSelectedLocationId}
              />
            </div>
          </Stack>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        className={selectedLanguage === "ar" ? "cal-ender1" : "cal-ender2"}
        sm={8}
        md={8}
        sx={{
          margin: "0px",
          paddingLeft:
            selectedLanguage === "ar" ? "0px !important" : "20px !important",
          paddingRight:
            selectedLanguage === "ar" ? "10px !important" : "0px !important",

          maxWidth: isMobile ? "95%" : "64% !important",
        }}
      >
        <Box
        className="calender-box"
          sx={{
            height: isMobile ? "600px" : "410px",
            overflowY: "auto",
            // marginBottom: isMobile1 ? "70px" : "",
            overflowX: "hidden",
            scrollbarColor: "transparent transparent",
            "&::-webkit-scrollbar": {
              width: isMobile ? "0" : "6px", // Show scrollbar width on desktop, hide on mobile
              display: isMobile ? "none" : "block",
              marginLeft: "20px !important",
            },
            "&::-webkit-scrollbar-track": {
              background: "rgba(0, 0, 0, 0.1)",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(8, 79, 140, 0.5)",
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "rgba(8, 79, 140, 0.7)",
            },
            "&::-webkit-scrollbar-thumb:active": {
              background: "rgba(8, 79, 140, 0.9)",
            },
          }}
        >
          <Calendar
            onChange={handleSelectedDate}
            onClickDay={handleSelectedDate}
            className="custom-calendar"
            value={selectedDate}
            prev2Label={null}
            next2Label={null}
            formatMonthYear={formatMonthYear}
            defaultValue={new Date()}
            selected={selectedDate}
            minDate={new Date()} // Show dates from the current date onwards
            tileDisabled={({ date }) =>
              isBefore(date, subDays(startOfWeekDate, 1)) ||
              isBefore(date, subDays(new Date(), 1))
            }
            minDetail="month"
            maxDetail="month"
            maxDate={nextMonthEnd}
            tileClassName={tileClassName}
            showNeighboringMonth={true} // Hide neighboring month dates
            // locale={customLocale}
            // locale="ar"
            locale={selectedLanguage === 'ar' ? 'ar' : 'en-US'} // Set locale based on selectedLanguage

          />

          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              fontFamily: "Cairo",
              width: "94% !important",
              color: "rgba(45, 44, 48, 1)",
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
              margin: { xs: "30px 0px 14px 0px", sm: "14px 0px 14px 0px" },
              // height: isMobile ? "670px" : "410px",
            }}
          >
            {!isMobile
              ? `${t("chooseTimeFor")} ${formattedDate}`
              : ` ${t("chooseTime")}`}
          </Typography>

          <Box
                      className="tags-padding"

            sx={{
              backgroundColor: "white",
              fontFamily: "Cairo",

              // width: "90% !important",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              overflowY: "auto",
              overflowX: "hidden",
              marginBottom: isMobile ? "200px" : "",
            }}
          >

            <Grid container  minHeight="56px" 
            // sx={{width:"100% !important", columnGap:"1rem" ,margin:"0px !important"}} 
            className="tags-column-gap slots-grid">
              {  
               typeof timeSlotData === 'string'
                ? (<Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontFamily: "Cairo",
                  letterSpacing: "1px",
                  color: "grey",
                }}
              >
              No slots available
              </Typography>
            </Grid>):
            (Object.keys(timeSlots).map((item, idx) => {
              console.log(item, "item")
              return(
                <div key={idx}>
                          <Grid
                            item
                            xs={4}
                            sm={3}
                            md={4}
                            key={idx}
                            sx={{ direction: "ltr" }}
                          >
                            <Timetags
                              time={item}
                              setSelectedTime={setSelectedTime}
                              selectedTime={selectedTime}
                              setSelectedTimeObj={setSelectedTimeObj}
                              slots={timeSlots}
                            />
                          </Grid>
                        </div>
              )
            }))}


            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DateTime;
