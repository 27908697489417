import { Box, Container, Dialog, Divider, Grid, Stack } from "@mui/material";

import Summary from "./Summary/Summary";
import Services from "./Services/Services";
import DateTime from "./DateTime/DateTime";
import CustomerDetails from "./Details/CustomerDetails";
import Header from "./Common/Header";
import Footer from "./Common/Footer";
import { tabs } from "../App";
import { useEffect, useState } from "react";
import Receiptt from "./Receipt/Receiptt";
import Booking from "./Booking/Booking";
import ReviewCard from "./Common/ReviewCard";
import axiosInstance from "../api/ApiInstance";
import zIndex from "@mui/material/styles/zIndex";
import moment from "moment";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDate } from "../helpers";
import Summary2 from "./Summary/Summary2";
import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";

const PopupModal = ({
  handleClose,
  open,
  selectedTab,
  setSelectedTab,
  selectedServices,
  setSelectedServices,
  setRtL,
  setSelectedLanguage,
  addItemToServiceList,
  selectedLanguage,
}) => {
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  ///////////timer/////////

  const [timer, setTimer] = useState(null); // State to hold the timer
  const [createSessionCalled, setCreateSessionCalled] = useState(false);

  const [language, setLanguage] = useState(sessionStorage.getItem("language"));
  const [previousTab, setPreviousTab] = useState(tabs?.services); 

  const [locations, setLocations] = useState([]);
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [mobileView, setMobileView] = useState(isMobileView);
  const [selectedTime, setSelectedTime] = useState("");
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [nameDetails, setNameDetails] = useState("");
  const [allServices, setAllServices] = useState([]);
  const [otpConfirmed, setOtpConfirmed] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [timeSlotData, setTimeSlotData] = useState([]);

  const [selectedTimeObj, setSelectedTimeObj] = useState(null);

  const [otp, setOtp] = useState("");
  const [verifyOtp, setVerifyOtp] = useState("");
  const [enablePayment, setEnablePayment] = useState("");

  const [estTime, setEstTime] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState(
    sessionStorage.getItem("selectedLocationId")
    // || locations?.[0]?.id
  );

  const [selectedLocation, setSelectedLocation] = useState(
    sessionStorage.getItem("selectedLocation")
    // || locations?.[0]?.area
  );
  const [bookingParams, setBookingParams] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [isServiceSelected, setIsServiceSelected] = useState(false);
  const [username, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [notes, setNotes] = useState("");
  const [bookingAmount, setBookingAmount] = useState("");
  const [secondaryOption, setSecondaryOption] = useState("");
  const [costt1, setCost1] = useState("");
  const [costt2, setCost2] = useState("");
  const [subtotal, setSubtotal] = useState("");
  
  const { i18n, t } = useTranslation();

  const serviceIds = Object.values(selectedServices).map(
    (service) => service.id
  );

  const handleClickOpenOtpModal = () => {
    setOpenOtpModal(true);
  };

  const handleCloseOtpModal = () => {
    setOpenOtpModal(false);
  };
 
  useEffect(() => {
  //   function arabicToEnglish(contactNumber) {
  //     const arabicNumbers = "٠١٢٣٤٥٦٧٨٩";
  //     return contactNumber.replace(/[\u0660-\u0669]/g, (d) =>
  //       arabicNumbers.indexOf(d)
  //     );
  //   }
    
  //   const arabicNumber = "٩٦٢٥٠٦٤٠";
  //   const englishNumber = arabicToEnglish(arabicNumber);
    
  //   console.log(englishNumber,"ppppp"); 
  //  setContactNumber(englishNumber)
    
    // alert(contactNumber)
    
    
  },[contactNumber])

  const showHeaderAndFooter = selectedTab !== tabs?.confirmBooking;
  const showFooter =
    selectedTab !== tabs?.confirmBooking && selectedTab !== tabs?.reviewBooking;
  const hideFooteronReview = selectedTab !== tabs?.reviewBooking;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const arrayOfIds = allServices.map((obj) => obj.id);
  //*******************Customer details states************************//
  const getBookingParams = async () => {
    try {
      const response = await axiosInstance.get("/booking/params/");
      setBookingParams(response.data);
      const areas = response?.data?.[0]?.service_areas?.map((item) => {
        return { area: item?.area, id: item?.id };
      }); // Extract area names
      setLocations(areas);
      setSelectedLocation(areas?.[0]?.area);
      setSelectedLocationId(areas?.[0]?.id);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getServices = async () => {
      try {
        const response = await axiosInstance.get("/services/list/");

        setAllServices(response.data);
      
      } catch (error) {
        console.log(error);
      }
    };


    ///////////Locations//////////////

    getServices();
  }, [locations]);

  useEffect(() => {
  
    getBookingParams();
  }, []);

  const handleDeleteService = (item) => {
    const filteredArray = selectedServices.filter(
      (service) => service?.id !== item?.id
    );
    setSelectedServices(filteredArray);
  };

  /***************fromatted date ********************/
  const [formattedDate, setFormattedDate] = useState(
    moment(selectedDate).format("YYYY-MM-DD")
  );
  // const formattedDate = moment(selectedDate).format("YYYY-MM-DD");

  const dateeeee = new Date(formattedDate);

  //***************API CAllss********************8//
  const deets = { bookingId, selectedDate, selectedTime, selectedLocation };
  const orderdeets = sessionStorage.setItem(
    "orderdeets",
    JSON.stringify(deets)
  );
  const getTimeSlots = async () => {
    try {
      const response = await axiosInstance.get(
        `/booking/time-slots/?date=${selectedDate}&location_id=${selectedLocationId}&estimated_time=${estTime}`
      );
   console.log(response.data,"here")
   setTimeSlotData(response.data)
      setTimeSlots(response.data.slots);
     
    } catch (error) {
      console.log(error);
    }
  };

 

  const getOTP = async () => {
    try {
      const services = selectedServices.map((service) => ({
        id: service.id,
        quantity: service.quantities[0].service_quantity,
      }));

      const requestBody = {
        phone: "968" + contactNumber,
      };
      const response = await axiosInstance.post(
        `/booking/send/otp/`,
        requestBody
      );
  //  alert(response.data.OTP)

      setOtp(response.data);
     
    } catch (error) {
      console.log(error);
    }
  };

  /*********************      POST OTP    ***************************/

  const orderDetails = async () => {
    try {
      const services = selectedServices.map((service) => ({
        id: service.id,
        quantity: service.quantities[0].service_quantity,
      }));

      const requestBody = {
        otp: otp.OTP,
        location: selectedLocationId,
        service: services,
        client_name: username,
        client_email: email,
        slot: selectedTime,
        asset_ids: selectedTimeObj,
        estimated_time: estTime,
        client_phone: "968" + contactNumber,
        client_optional_phone: "968" + secondaryOption,
        booking_date: selectedDate,
        notes: notes,
        sub_total: subtotal,
        extra_cost: costt1,
        vat: costt2,
        booking_amount: bookingAmount,
      };

      const response = await axiosInstance.post(
        `/booking/verify/otp/`,
        requestBody
      );
      sessionStorage.removeItem("sessionId");
      const bookingID = response.data.id;
     
      setBookingId(bookingID);
      setOtpConfirmed(response.data.flag);
const responseData = response
    
      if (response.status === 200) {
        console.log(response.data,responseData.data,"data here")
        sessionStorage.setItem("bookingId", bookingID);
        sessionStorage.setItem("selectedDate", selectedDate);
        sessionStorage.setItem("selectedTime", selectedTime);
        sessionStorage.setItem(
          "bookingParams",
          bookingParams?.[0]?.display_time_range
        );

        if(response.status === 200 && !Array.isArray(responseData.data)){
        toast.error(responseData.data)
        }
     
          // ... (existing code)
        
          // Set the timer for 1 minute (60000 milliseconds)
          const newTimer = setTimeout(() => {
            // Display error toast if create-session API is not called
            if (!createSessionCalled) {
              toast.error('Your time session is expired. Please rebook your services.', {
                position: toast.POSITION.TOP_RIGHT,
              });
              const refreshTimer = setTimeout(() => {
                window.location.reload();
              }, 15000);
             
            }
          }, 420000); // 60000 milliseconds = 1 minute
        
          // Clear the previous timer if it exists
          if (timer) {
            clearTimeout(timer);
          }
        
          // Set the new timer to the state
          setTimer(newTimer);
        } else {
          console.log('Order details submission failed!');
        }
    } catch (error) {
      console.log(error);
    }
  };

  /*****************     CHECKOUT        ***************/
  const payCheckout = async () => {
    try {
      const requestBody = {
        id: bookingId,
      };

      const response = await axiosInstance.post(
        "/thawani/create-session/",
        requestBody
      );

      const sessiondata = response.data.data.session_id;
      // alert(sessiondata)
      sessionStorage.setItem("sessionId", sessiondata);

      setSessionId(sessiondata);

      const redirectUrl = response.data.redirect_url;

      // Redirect to the received URL
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }

      if (response.status === 200) {
        setCreateSessionCalled(true);

        if (timer) {
          clearTimeout(timer);
          setTimer(null); // Reset the timer state to null
        }
      } else {
        console.log("cant checkout");
      }
    } catch (error) {
      console.log(error);
    }
  };
  /************           retrieve session         *****************/

  const retrievePaymentSession = async () => {
    try {
      const retrievedSession = sessionStorage.getItem("sessionId");


      const response = await axiosInstance.get(
        `/thawani/retrieve-session/${retrievedSession}/`
      );
      sessionStorage.removeItem("sessionId");

      setBookingId(response.data.order_id);
      setSelectedLocation(response.data.location.area);
      setSelectedTime(response.data.slot);
      setSelectedLocationId(response.data.location.id);

      // setSelectedDate(response.data.booking_date);

      if (response.status === 200) {
        
        console.log("checkout!");
      } else {
        console.log("cant checkout");
      }
    } catch (error) {
      console.log(error);
    }
  };

  


  //////////INCREMRNT AND DECREMNT//////////
  const decrementQuantity = (item) => {
    // Find the index of the selected item
    const foundItemIndex = selectedServices.findIndex(
      (service) => service.id === item.id
    );

    if (foundItemIndex !== -1) {
      // Create a copy of the selectedServices array
      const updatedServices = [...selectedServices];

      // Check if the current quantity is greater than 0
      if (updatedServices[foundItemIndex].quantities[0].service_quantity > 0) {
        // Decrement the quantity by 1
        updatedServices[foundItemIndex].quantities[0].service_quantity -= 1;

        // If the updated quantity becomes 0, remove the item from selectedServices
        if (
          updatedServices[foundItemIndex].quantities[0].service_quantity === 0
        ) {
          updatedServices.splice(foundItemIndex, 1);
        }

        // Set the state with the updated selectedServices array
        setSelectedServices(updatedServices);
      }
    }
  };

  const incrementQuantity = (item) => {
    // Find the index of the selected item
    const foundItemIndex = selectedServices.findIndex(
      (service) => service.id === item.id
    );

    if (foundItemIndex !== -1) {
      // Item already exists in the selectedServices array, so increment the quantity
      const updatedServices = [...selectedServices];
      const currentQuantity =
        updatedServices[foundItemIndex].quantities[0].service_quantity;

      if (currentQuantity < 15) {
        // Only increment if the current quantity is less than 15
        updatedServices[foundItemIndex].quantities[0].service_quantity += 1;
        setSelectedServices(updatedServices);
      }
    } else {
      // Item does not exist in the selectedServices array, so add it with quantity 1
      addItemToServiceList({
        ...item,
        quantities: [{ service_quantity: 1 }],
      });
    }
  };

  const handleSelectedDate = (date) => {
    setSelectedDate(moment(date).format("YYYY-MM-DD"));
  };
  const calculateTotalTime = () => {
    let totalTime = 0;
    let totalTime1 = 0;
    const buffertime = bookingParams?.[0]?.buffer_time;
    selectedServices?.map((time) => {
      // totalTime += time.duration;
      totalTime += time?.duration * time?.quantities[0]?.service_quantity;
      // totalTime += buffertime;
    });

    const hours = Math.floor(totalTime / 60);
    const minutes = totalTime % 60;
    const time = hours * 60 + minutes;
    setEstTime(time);

    return `${hours} hours ${minutes} minutes`;
  };

  const calculateSubtotal = () => {
    const subtotal = selectedServices?.reduce?.(
      (accumulator, service) =>
        accumulator + service.price * service.quantities[0].service_quantity, // Multiply price by quantity
      0
    );

    return subtotal;
  };
  const cost1 = () => {
    const extraCost = bookingParams?.[0]?.extra_cost?.[0];
  
    if (selectedServices.length > 0) {
      if (extraCost) {
        if (extraCost.onOFF === true || extraCost.onOFF === "true") {
          if (extraCost.cost_type.toLowerCase() === "percentage") {
            return (parseFloat(calculateSubtotal()) * extraCost.cost) / 100;
          } else {
            return extraCost.cost;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };
  
  // const cost2 = () => {
  //   const extraCost = bookingParams?.[0]?.extra_cost?.[1];

  
  //   if (extraCost && extraCost.onOFF==="true"  && selectedServices.length > 0) {
  //     console.log(extraCost.onOFF,"extras","jkjkjj")
  //     if (  extraCost.cost_type === "Percentage" ||
  //     extraCost.cost_type === "percentage") {
  //       return (
  //         (parseFloat(calculateSubtotal()) * extraCost.cost) /
  //         100
  //       );
  //     } else if (
  //       extraCost.cost_type !== "Percentage" ||
  //       extraCost.cost_type !== "percentage"
  //     ) {
  //       return extraCost?.cost;
  //     } else if (selectedServices.length < 0) {
  //       return 0.0;
  //     }
  //   }
  //   else if (extraCost?.onOFF==="false"){
  //     return "0"
  //   }
  //   else{
  //     return "0"
  //   }
  //   return 0

  // };
  const cost2 = () => {
    const extraCost = bookingParams?.[0]?.extra_cost?.[1];
  
    if (selectedServices.length > 0) {
      if (extraCost) {
        if (extraCost.onOFF === true || extraCost.onOFF === "true") {
          if (extraCost.cost_type.toLowerCase() === "percentage") {
            return (parseFloat(calculateSubtotal()) * extraCost.cost) / 100;
          } else {
            return extraCost.cost;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };
  
  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal());
    const cost11 = parseFloat(cost1());
    const cost22 = parseFloat(cost2());

    // let total = subtotal + cost11 + cost22;
    if (
      bookingParams?.[0]?.extra_cost?.[0].onOFF &&
      bookingParams?.[0]?.extra_cost?.[1].onOFF
    ) {
      return (subtotal + cost11 + cost22);
    }
    if (
      !bookingParams?.[0]?.extra_cost?.[0].onOFF &&
      !bookingParams?.[0]?.extra_cost?.[1].onOFF
    ) {
      return (subtotal);
    }
    if (
      !bookingParams?.[0]?.extra_cost?.[0].onOFF &&
      bookingParams?.[0]?.extra_cost?.[1].onOFF
    ) {
      return (subtotal + cost22);
    }
    if (
      bookingParams?.[0]?.extra_cost?.[0].onOFF &&
      !bookingParams?.[0]?.extra_cost?.[1].onOFF
    ) {
      return (subtotal + cost11);
    }
    // setBookingAmount(total.toFixed(2));
    if (subtotal === 0 || cost11 === 0 || cost22 === 0) {
      return (0);
    }
  };

  useEffect(() => {
    calculateTotalTime();
    sessionStorage.setItem("estTime", calculateTotalTime());
    setCost1(cost1());

    setCost2(cost2());
    setSubtotal(calculateSubtotal());
    setBookingAmount(calculateTotal());
    console.log(bookingAmount,selectedServices,calculateTotal(),"bookingAmount1")
  }, [selectedServices]);

  return (
    // * Main popup modal
    <Dialog
      maxWidth={{ xs: "100vw" }}
      maxHeight={{ xs: "100vw" }}
      fullWidth
      // BackdropProps={{ invisible: true }} 
      // // disableBackdropClick
      // // disableBackdropClick={true}
      disableEscapeKeyDown
      className="popupmodal"
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          position: "relative",
          width: "1030px",
          overflowY: "hidden",

         
          margin: "0px",
          
          backgroundColor: "rgba(250, 250, 250, 1)",
          // backgroundColor:"red !important"
        },
        ...(isMobile && { height: "100%" }),
      }}
    >
    
      <ToastContainer />
      <Grid
        container
        sx={{
          maxHeight: { xs: "100vh" },
        }}
      >
        {/* Left Grid */}
        <Grid item xs={12} sm={12} md={8} sx={{}}>
          <Stack
            direction="column"
            justifyContent="space-between"
            spacing={2}
            sx={{
              display: "flex",
              position: "relative",
              // backgroundColor: "pink",
              flexDirection: "column",
              paddingY: { sm: "20px 25px 30px 25px", xs: "2px 2px" },
              // borderLeft: { sm: "1px solid #DCDCDC", xs: "none" },
              borderLeft: !isMobile ? "1px solid #DCDCDC" : "",
            }}
          >
            {/* Header Component  =============================================  */}
            {showHeaderAndFooter && (
              <Container
                className={!isMobile ? "container" : ""}
                sx={{
                  position: { xs: "sticky", sm: "unset" },
                  top: { xs: 0 },
                  zIndex: { xs: "3" },
                  backgroundColor: "rgba(250, 250, 250, 1)",
                }}
              >
                <Header
                                  bookingParams={bookingParams}

                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  setRtL={setRtL}
                  setSelectedLanguage={setSelectedLanguage}
                  selectedLanguage={selectedLanguage}
                  selectedServices={selectedServices}
                  setSelectedServices={setSelectedServices}
                  isServiceSelected={isServiceSelected}
                  setIsServiceSelected={setIsServiceSelected}
                  otpConfirmed={otpConfirmed}
                  previousTab={previousTab}
                  setPreviousTab={setPreviousTab}
                />
              </Container>
            )}

            {/* Main Content switch according to selected tab  =============================================  */}
            <Container
              // className={
              //   selectedTab === tabs.services ? "container-padding" : ""
              // }

              sx={{
                flexGrow: 1,
                marginTop: isMobile ? "0px !important" : "",
                padding: "0px 15px !important",
                alignItems: "space-between !important",
                // height: isMobile ? "calc(100vh - 226px)" : "",
                // overflowY: "auto",
                "&::-webkit-scrollbar": {
                  display: " none",
                },
              }}
            >
              {selectedTab === tabs?.services ? (
                <Services
                  setSelectedServices={setSelectedServices}
                  selectedServices={selectedServices}
                  setSelectedTab={setSelectedTab}
                  addItemToServiceList={addItemToServiceList}
                  selectedLanguage={selectedLanguage}
                  allServices={allServices}
                  bookingParams={bookingParams}
                  setRtL={setRtL}
                  setSelectedLanguage={setSelectedLanguage}
                  getTimeSlots={getTimeSlots}
                  // getLocation={getLocation}
                  calculateSubtotal={calculateSubtotal}
                  calculateTotal={calculateTotal}
                  cost1={cost1}
                  cost2={cost2}
                  incrementQuantity={incrementQuantity}
                  decrementQuantity={decrementQuantity}
                  selectedTab={selectedTab}
                />
              ) : null}
              {selectedTab === tabs?.dateTime ? (
                <DateTime
                  handleSelectedDate={handleSelectedDate}
                  setSelectedTab={setSelectedTab}
                  selectedDate={selectedDate}
                  setSelectedTime={setSelectedTime}
                  selectedTime={selectedTime}
                  selectedServices={selectedServices}
                  selectedLanguage={selectedLanguage}
                  arrayOfIds={arrayOfIds}
                  options={locations}
                  timeSlots={timeSlots}
                  setSelectedTimeObj={setSelectedTimeObj}
                  selectedLocation={selectedLocation}
                  setSelectedLocation={setSelectedLocation}
                  selectedLocationId={selectedLocationId}
                  setSelectedLocationId={setSelectedLocationId}
                  getTimeSlots={getTimeSlots}
                  // getLocation={getLocation}
                  bookingParams={bookingParams}
                  timeSlotData={timeSlotData}
                />
              ) : null}
              {selectedTab === tabs?.customerDetails ? (
                <CustomerDetails
                  openOtpModal={openOtpModal}
                  handleCloseOtpModal={handleCloseOtpModal}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  setNameDetails={setNameDetails}
                  nameDetails={nameDetails}
                  selectedLanguage={selectedLanguage}
                  setOtpConfirmed={setOtpConfirmed}
                  username={username}
                  email={email}
                  contactNumber={contactNumber}
                  secondaryOption={secondaryOption}
                  setName={setName}
                  setEmail={setEmail}
                  notes={notes}
                  setNotes={setNotes}
                  setContactNumber={setContactNumber}
                  setSecondaryOption={setSecondaryOption}
                  getOTP={getOTP}
                  orderDetails={orderDetails}
                  otp={otp.OTP}
                  verifyOtp={verifyOtp}
                  selectedLocationId={selectedLocationId}
                  selectedTime={selectedTime}
                  estTime={estTime}
                  formattedDate={formattedDate}
                  bookingAmount={bookingAmount}
                  bookingParams={bookingParams}
                  setVerifyOtp={setVerifyOtp}
                  calculateTotalTime={calculateTotalTime}
                  calculateSubtotal={calculateSubtotal}
                  calculateTotal={calculateTotal}
                  cost1={cost1}
                  cost2={cost2}
                  otpConfirmed={otpConfirmed}
                  enablePayment={enablePayment}
                  setEnablePayment={setEnablePayment}
                />
              ) : null}
              {selectedTab === tabs?.confirmBooking ? (
                <Booking
                  setRtL={setRtL}
                  setSelectedLanguage={setSelectedLanguage}
                  selectedTab={selectedTab}
                  selectedLanguage={selectedLanguage}
                  setSelectedTab={setSelectedTab}
                  selectedServices={selectedServices}
                  selectedDate={selectedDate}
                  selectedTime={selectedTime}
                  retrievePaymentSession={retrievePaymentSession}
                  selectedLocation={selectedLocation}
                  bookingId={bookingId}
                  bookingParams={bookingParams}
                  bookingAmount={bookingAmount}
                  setBookingAmount={setBookingAmount}
                  getTimeSlots={getTimeSlots}
                  // getLocation={getLocation}
                  sessionId={sessionId}
                  setSessionId={setSessionId}
                  estTime={estTime}
                  setEstTime={setEstTime}
                  calculateTotalTime={calculateTotalTime}
                  calculateSubtotal={calculateSubtotal}
                  calculateTotal={calculateTotal}
                  cost1={cost1}
                  cost2={cost2}
                  setSelectedServices={setSelectedServices}
                  setSelectedTime={setSelectedTime}
                  otpConfirmed={otpConfirmed}
                  setOtpConfirmed={setOtpConfirmed}
                />
              ) : null}
              {selectedTab === tabs?.reviewBooking ? (
                <ReviewCard
                  selectedTab={selectedTab}
                  selectedServices={selectedServices}
                  selectedLanguage={selectedLanguage}
                  setSelectedTab={setSelectedTab}
                  selectedDate={selectedDate}
                  selectedTime={selectedTime}
                  handleDeleteService={handleDeleteService}
                  decrementQuantity={decrementQuantity}
                  incrementQuantity={incrementQuantity}
                  bookingParams={bookingParams}
                  bookingAmount={bookingAmount}
                  setBookingAmount={setBookingAmount}
                  estTime={estTime}
                  setEstTime={setEstTime}
                  calculateTotalTime={calculateTotalTime}
                  calculateSubtotal={calculateSubtotal}
                  calculateTotal={calculateTotal}
                  cost1={cost1}
                  cost2={cost2}
                />
              ) : null}
            </Container>

            {/* Footer */}
            {showFooter && (
              <Container
                className={!isMobile ? "container" : ""}
                sx={{
                  position: { xs: "fixed", sm: "unset" },
                  bottom: { xs: 0 },
                  backgroundColor: "250, 250,250,1",
                }}
              >
                <Footer
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  selectedLanguage={selectedLanguage}
                  otpConfirmed={otpConfirmed}
                  setOtpConfirmed={setOtpConfirmed}
                  payCheckout={payCheckout}
                  retrievePaymentSession={retrievePaymentSession}
                  estTime={estTime}
                  bookingParams={bookingParams}
                  bookingId={bookingId}
                  setVerifyOtp={setVerifyOtp}
                  otp={otp}
                  verifyOtp={verifyOtp}
                  selectedTime={selectedTime}
                  enablePayment={enablePayment}
                  previousTab={previousTab}
                  setPreviousTab={setPreviousTab}
                />
              </Container>
            )}
          </Stack>
        </Grid>
        {/* {!isMobile && (
          <Divider
            sx={{ height: "75vh", overflowY: "auto" }}
            orientation="vertical"
          />
        )} */}

        {/* Right Grid & Summary Content ============================================= */}
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          sx={{
            // borderLeft: { sm: "1px solid #DCDCDC", xs: "none" },
            borderLeft: !isMobile ? "1px solid #DCDCDC" : "",
            // padding: "10px",
            position: "relative",
          }}
        >
          {/* On mobile hide summary grid */}
          {!isMobile &&
            showHeaderAndFooter &&
            (selectedTab === tabs?.services ? (
              <Summary
                selectedServices={selectedServices}
                handleDeleteService={handleDeleteService}
                incrementQuantity={incrementQuantity}
                decrementQuantity={decrementQuantity}
                selectedDate={selectedDate}
                selectedTime={selectedTime}
                selectedLanguage={selectedLanguage}
                bookingParams={bookingParams}
                bookingAmount={bookingAmount}
                estTime={estTime}
                setEstTime={setEstTime}
                calculateTotalTime={calculateTotalTime}
                setBookingAmount={setBookingAmount}
                calculateSubtotal={calculateSubtotal}
                calculateTotal={calculateTotal}
                cost1={cost1}
                cost2={cost2}
                selectedTab={selectedTab}
              />
            ) : (
              <Summary2
                selectedServices={selectedServices}
                handleDeleteService={handleDeleteService}
                incrementQuantity={incrementQuantity}
                decrementQuantity={decrementQuantity}
                selectedDate={selectedDate}
                selectedTime={selectedTime}
                selectedLanguage={selectedLanguage}
                bookingParams={bookingParams}
                bookingAmount={bookingAmount}
                estTime={estTime}
                setEstTime={setEstTime}
                calculateTotalTime={calculateTotalTime}
                setBookingAmount={setBookingAmount}
                calculateSubtotal={calculateSubtotal}
                calculateTotal={calculateTotal}
                cost1={cost1}
                cost2={cost2}
              />
            ))}

          {/* {showHeaderAndFooter && <Summary />} */}
          {!showHeaderAndFooter && !isMobile && (
            <Receiptt
              selectedServices={selectedServices}
              selectedDate={selectedDate}
              selectedTime={selectedTime}
              selectedLanguage={selectedLanguage}
              bookingParams={bookingParams}
              bookingAmount={bookingAmount}
              setBookingAmount={setBookingAmount}
              estTime={estTime}
              calculateTotalTime={calculateTotalTime}
              setEstTime={setEstTime}
              calculateSubtotal={calculateSubtotal}
              calculateTotal={calculateTotal}
              cost1={cost1}
              cost2={cost2}
            />
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default PopupModal;
