import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";


import { useTranslation } from "react-i18next";
import { Modal } from "@mui/material";

const ServiceCard = ({
  data,
  setSelectedServices,
  selectedServices,
  addItemToServiceList,
  selectedLanguage,
  incrementQuantity,
  decrementQuantity,
}) => {
  const { i18n, t } = useTranslation();

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

 
  const handleData = () => {
    const existingItem = selectedServices.find((item) => item.id === data.id);

    if (existingItem) {
      // Item already exists in the selectedServices array, so increment the quantity
      incrementQuantity(data);
    } else {
      // Item does not exist in the selectedServices array, so add it with quantity 1
      addItemToServiceList({
        ...data,
        quantities: [{ service_quantity: 1 }],
      });
    }
  };
  return (
    <div
      style={{
        maxWidth: isMobile ? "185px" : "205px",

        position: "relative",
        marginTop:
          isMobile && selectedLanguage !== "ar"
            ? "40px"
            : !isMobile && selectedLanguage === "ar"
              ? "31px"
              : "35px",
      }}
    >
      <div
        className={
          selectedLanguage === "ar" ? "service-card-img2" : "service-card-img"
        }
        style={{
          backgroundColor: data?.photo ? "white" : "rgba(216, 216, 216, 1)",
          width: "70px !important",
          height: "70px !important",
        }}
      >
        {/* <img src={data?.photo} alt="imgg" /> */}
        {data?.photo && (
          <img
            src={data.photo}
            alt="imgg"
            style={{ width: "50px", height: "50px" }}
          />
        )}
      </div>
      {/* <div
        style={{
          position: "absolute",
          backgroundColor: "white",
          top: "15px",
          right: "15px",
          width: "20px",
          height: "20px",
        }}
      >
        <InfoOutlinedIcon
          sx={{ color: "rgba(8, 79, 140, 1)", cursor: "pointer" }}
          onClick={handleOpen}
        />
      </div> */}

      <Card
        variant="outlined"
        sx={{
          border: "none",
          borderRadius: "13px",
          padding: "0px 5px 50px 5px",
          margin: "0px",
          width: "auto",
          height: "184px",

        }}
      >
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            textAlign: "center",
            marginTop: selectedLanguage==="ar"?"16px": "20px",
            paddingLeft: selectedLanguage === "ar" ? "0px !important" : "",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",

              lineHeight: selectedLanguage === "ar" ? "1.3" : "1.1",
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
              margin:
                selectedLanguage === "ar"
                  ? "19px 10px 10px 10px !important"
                  : "20px 0px 5px 0px",
            }}
            color="rgba(45, 44, 48, 1)"
            gutterBottom
          >
            {selectedLanguage === "ar" ? data.name_ar : data.name_en}
          </Typography>
          <Typography
            sx={{
              // mb: 1.5,
              fontFamily: "Cairo",
              fontSize: "14px",

              fontWeight: "bold",
              lineHeight: "0.8",
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
            }}
            color="rgba(8, 79, 140, 1)"
          >
            OMR {data.price}
          </Typography>
          <Typography
            variant="body2"
            color="rgba(8, 79, 140, 1)"
            sx={{
              fontFamily: "Cairo",
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
              fontSize: "12px",
              lineHeight: "0.8",
              direction: "ltr !important",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "5px",
                direction: "ltr !important",
              }}
            >
              <AccessTimeIcon
                // fontSize="small"
                fontSize="13px"
                style={{
                  lineHeight: "1",
                  paddingRight: "3px",

                  // paddingLeft: selectedLanguage === "ar" ? "3px" : "",
                }}
                sx={{ height: "1.2em !important", width: "1.5em" }}
              />

              <span>{data?.duration} Min</span>
            </div>
          </Typography>
          <Typography
          //  variant="body2"
           color="rgba(8, 79, 140, 1)"
           sx={{fontWeight:"bold",
           padding:"8px 0px",
           
           fontSize: selectedLanguage==="ar"?"11px":"13px",cursor:"pointer"}}
           onClick={handleOpen}
           >

{t("viewDetails")}
          </Typography>
          {!isMobile ? (
            <Button
              size="small"
              style={{
                width: "70%",
                position: "absolute",
                bottom: "10px",
                // height: "34px",
                color: "white",
                backgroundColor: "rgba(0, 223, 201, 1)",
                // fontWeight: "bold",
                borderRadius: "15px",
                marginTop: "10px",
                padding: "5px 2px",
                letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
              }}
              onClick={() => addItemToServiceList(data)}
            >
              ADD
            </Button>
          ) : (
            <Stack
              direction="row"
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                padding:
                  selectedLanguage === "ar"
                    ? "10px 0px 0px 0px"
                    : "10px 0px 0px 0px",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  cursor: "pointer",
                  // backgroundColor: "#FAFAFA",
                  borderRadius: "50%",
                  height: "22px",
                  width: "22px",

                  color: "white",
                  fontFamily: "Cairo",
                  backgroundColor: "rgba(8, 79, 140, 1)",
                  textAlign: "center",
                  lineHeight: "1.4 !important",
                }}
                onClick={() => decrementQuantity(data)}
              >
                -
              </Typography>
             

              <Typography
                sx={{
                  fontSize: "14px",
                  cursor: "pointer",
                  borderRadius: "5px",
                  height: "22px",
                  width: "22px",
                  color: "#084F8C",
                  fontFamily: "Cairo",
                  backgroundColor: "rgba(250, 250, 250, 1)",
                  margin: "0px 7px ",
                  textAlign: "center",
                }}
              >
                {
                  selectedServices.some((item) => item.id === data.id) // Check if item exists in selectedServices
                    ? selectedServices.find((item) => item.id === data.id)
                      .quantities[0].service_quantity // If it exists, display the quantity
                    : "0" // If it does not exist, display "0"
                }
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  cursor: "pointer",
                  // backgroundColor: "#FAFAFA",
                  borderRadius: "50%",
                  height: "22px",
                  width: "22.5px",

                  color: "white",
                  fontFamily: "Cairo",
                  backgroundColor: "rgba(8, 79, 140, 1)",

                  textAlign: "center",
                  lineHeight: "1.45 !important",
                }}
                onClick={handleData}
              >
                +
              </Typography>
            </Stack>
          )}
        </Stack>
      </Card>

      <Modal open={open} >
        {/* onClose={handleClose} */}
        <Box
          sx={{
            position: "absolute",
            width: !isMobile ? "50%" : "80%",
            top: "50%",
            left: "50%",
            height: "350px",
            borderRadius: isMobile?"16px":"20px",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            fontFamily: "Cairo",
            border:"none",
            // border: "2px solid #084F8C",
            outline:"none",
            outlineColor: "none",
            outlineStyle: "none",
            fontSize: "14px",
            lineHeight: "20px",
            textAlign: selectedLanguage==="ar"?"right":"left",
            "&::-webkit-scrollbar": {
              width: isMobile ? "4px" : "6px",
              // display: isMobile ? "none" : "block",
            },
            "&::-webkit-scrollbar-track": {
              background: "rgba(0, 0, 0, 0.1)",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(8, 79, 140, 0.5)",
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "rgba(8, 79, 140, 0.7)",
            },
            "&::-webkit-scrollbar-thumb:active": {
              background: "rgba(8, 79, 140, 0.9)",
            },
          }}
        >
          <Stack direction="row" sx={{justifyContent:"space-between",marginBottom:"10px",direction:selectedLanguage==="ar"?"rtl !important":"ltr !important"}}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: "bold", color: "#084F8C",marginTop:"2px" }}>
            {t("serviceOverview")}    </Typography>  
          <Typography id="modal-modal-title" sx={{ fontFamily:"Varela Round",fontWeight: "bold",marginTop:"0px", fontSize: "24px", color: "black", cursor: "pointer", marginLeft: selectedLanguage==="ar"?"":"auto", width: "max-content",color:"gray" }} onClick={handleClose}>
         
            x
          </Typography>
          
          </Stack>
          
            <Box
              sx={{
                color: "gray",
                overflowY: "auto",
                height:"260px",
                "&::-webkit-scrollbar": {
                  width: isMobile ? "4px" : "6px",
                  // display: isMobile ? "none" : "block",
                },
                "&::-webkit-scrollbar-track": {
                  background: "rgba(0, 0, 0, 0.1)",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#084F8C",
                  borderRadius: "3px",
                },
              
              }}
              dangerouslySetInnerHTML={{
                __html:
                  selectedLanguage === "ar"
                    ? data.description_ar
                    : data.description_en
              }}
            ></Box>
        </Box>
      </Modal>

    </div>
  );
};
export default ServiceCard;
