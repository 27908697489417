import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import ReceiptCard from "./ReceiptCard";
// import DetailsCard from "../Summary/DetailsCard";
import MobileDetailsCard from "../Summary/MobileDetailsCard";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/system";
import DetailsCard from "./DetailsCard";
import ReceiptCard from "../Receipt/ReceiptCard";

const Summary2 = ({
  selectedServices,
  handleDeleteService,
  decrementQuantity,
  incrementQuantity,
  selectedDate,
  selectedTime,
  selectedLanguage,
  bookingParams,
  bookingAmount,
  setBookingAmount,
  estTime,
  setEstTime,
  calculateTotalTime,
  calculateSubtotal,
  calculateTotal,
  cost1,
  cost2,

  // calculateTotal,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();
  return (
    <Container className="receiptt ">
      <Stack
        direction="column"
        justifyContent="space-between"
        // paddingY={2}
        paddingBottom="8px"
        spacing={2}
        // sx={{ backgroundColor: "green" }}
      >
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
              textAlign: "center",
              marginTop: "25px",
              marginBottom: "20px",
              // marginBottom: "10px",
            }}
          >
            {t("summary")}
          </Typography>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            height: "294px",
            overflowY: "auto",
            // marginTop: { sm: "100px" },
            // marginBottom: "30px",
            // paddingBottom: "40px",
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(8, 79, 140, 0.5) rgba(0, 0, 0, 0.1)",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              background: "rgba(0, 0, 0, 0.1)",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(8, 79, 140, 0.5)",
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "rgba(8, 79, 140, 0.7)",
            },
            "&::-webkit-scrollbar-thumb:active": {
              background: "rgba(8, 79, 140, 0.9)",
            },
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            sx={{
              height: "calc(100vh - 587px)",
              // overflowY: "auto",
              marginBottom: "10px",
              // scrollbarColor: "transparent transparent",
              // "&::-webkit-scrollbar": {
              //   width: "6px",
              // },
              // "&::-webkit-scrollbar-track": {
              //   background: "rgba(0, 0, 0, 0.1)",
              // },
              // "&::-webkit-scrollbar-thumb": {
              //   background: "rgba(8, 79, 140, 0.5)",
              //   borderRadius: "3px",
              // },
              // "&::-webkit-scrollbar-thumb:hover": {
              //   background: "rgba(8, 79, 140, 0.7)",
              // },
              // "&::-webkit-scrollbar-thumb:active": {
              //   background: "rgba(8, 79, 140, 0.9)",
              // },
            }}
          >
            {selectedServices?.map?.((services, idx) => {
              return (
                <div key={idx}>
                  <ReceiptCard
                    data={services}
                    selectedLanguage={selectedLanguage}
                  />
                </div>
              );
            })}
          </Stack>
        </Box>
        <Box
          sx={{
            marginTop: "0px !important",
            maxHeight: "200px",
          }}
        >
          <DetailsCard
            selectedDate={selectedDate}
            selectedServices={selectedServices}
            selectedTime={selectedTime}
            selectedLanguage={selectedLanguage}
            bookingParams={bookingParams}
            bookingAmount={bookingAmount}
            setBookingAmount={setBookingAmount}
            estTime={estTime}
            setEstTime={setEstTime}
            calculateTotalTime={calculateTotalTime}
            calculateSubtotal={calculateSubtotal}
            calculateTotal={calculateTotal}
            cost1={cost1}
            cost2={cost2}
          />
        </Box>
      </Stack>
    </Container>
  );
};
export default Summary2;
