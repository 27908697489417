import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Image from "../../assets/Icon.svg";
import pexels from "../../assets/blue.jpg";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useTranslation } from "react-i18next";

const ReceiptCard = ({ data, selectedLanguage }) => {
  const Language1 = sessionStorage.getItem("language")
  console.log(data,data.name_ar,Language1,"receipt data")
  const { i18n, t } = useTranslation();

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          border: "none",
          borderRadius: "12px",
          padding: "5px 10px",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: "16px",
              fontFamily: "Cairo",
              marginBottom: "0px",
            }}
            color="rgba(8, 79, 140, 1)"
            gutterBottom
          >
            {Language1 === "ar"
    ? data.name_ar.length > 20
      ? `${data.name_ar.slice(0, 20)}..`
      : data.name_ar
    : data.name_en.length > 20
      ? `${data.name_en.slice(0, 20)}..`
      : data.name_en}
            {/* {selectedLanguage === "ar" ? data?.name_ar : data?.name_en} */}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
              fontFamily: "Cairo",
              marginBottom: "0px",
             
            }}
            color="rgba(8, 79, 140, 1)"
            gutterBottom
          >
            {selectedLanguage==="ar"?"x":""}{data?.quantities[0]?.service_quantity}{selectedLanguage!=="ar"?"x":""} {""} 
            {t("units")}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" sx={{ direction: "ltr" }}>
            <AccessTimeIcon
              fontSize="small"
              style={{
                lineHeight: "1",
                marginRight: selectedLanguage === "ar" ? "0" : "2px",
                color: "rgba(8, 79, 140, 1)",
              }}
            />
            <Typography
              variant="body2"
              sx={{
                fontSize: "14px",
                fontFamily: "Cairo",
                marginBottom: "0px",
              }}
              gutterBottom
            >
              <span>{data?.duration} Min</span>
            </Typography>
          </Stack>
          <Typography
            variant="body2"
            sx={{
              fontSize: "16px",
              fontFamily: "Cairo",
              marginBottom: "0px",
            }}
          >
            OMR {data?.price}
          </Typography>

        </Stack>
      </Card>
    </>
  );
};
export default ReceiptCard;
