import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import deleteicon from "../../assets/Layer 2.svg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

const SummaryCard = ({
  data,
  handleDeleteService,
  incrementQuantity,
  decrementQuantity,
  selectedLanguage,
}) => {
  return (
    <div>
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          border: "none",
          height: "77px",
          borderRadius: "13px",
          // maxWidth: "311px",
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          padding="4px 4px 4px 15px"
        >
          <Grid item md={2}>
            <div
              className="summary-card-img"
              style={{
                marginRight: "5px",
                backgroundColor: data?.photo
                  ? "white"
                  : "rgba(216, 216, 216, 1)", // Apply gray background color if data.photo is null
              }}
            >
              {/* <img src={data?.photo} alt="img" /> */}
              {data?.photo && <img src={data.photo} alt="imgg" />}
            </div>
          </Grid>
          <Grid item md={8} flexGrow={1}>
            <Stack
              direction="row"
              sx={{
                padding:
                  selectedLanguage === "ar"
                    ? "0px 20px 0px 0px"
                    : "0px 0px 0px 20px",
              }}
              alignItems="space-between"
              justifyContent="space-between"
            >
              <Stack
                direction="column"
                // justifyContent="space-between"
                sx={{
                  padding:
                    selectedLanguage === "ar"
                      ? "0px 0px 0px 20px"
                      : "0px 20px 0px 0px",
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", color: "#084F8C", lineHeight: "1.4" }}
                >
                  {selectedLanguage === "ar"
    ? data.name_ar.length > 12
      ? `${data.name_ar.slice(0, 12)}..`
      : data.name_ar
    : data.name_en.length > 12
      ? `${data.name_en.slice(0, 10)}..`
      : data.name_en}
                  {/* {selectedLanguage === "ar" ? data.name_ar.slice(0,12) : data.name_en.slice(0,12)} */}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "14px !important",
                    cursor: "pointer",
                    lineHeight: "1.4",
                    fontFamily: "Cairo",
                  }}
                >
                  OMR {data?.price}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "14px",
                    cursor: "pointer",
                    lineHeight: "1.4",
                    fontWeight: "500",
                    fontFamily: "Cairo",
                  }}
                >
                  {data?.duration} Min
                </Typography>
              </Stack>
              <Stack
                direction="column"
                alignItems="flex-end"
                justifyContent="space-between"
                sx={{
                  padding:
                    selectedLanguage === "ar"
                      ? "0px 20px 0px 20px"
                      : "0px 20px 0px 0px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    cursor: "pointer",
                    fontFamily: "Cairo",
                    fontWeight: "500",
                    color: "rgba(8, 79, 140, 1)",
                    width: "20px",
                    lineHeight: "1",
                    textAlign: "center",
                  }}
                  onClick={() => incrementQuantity(data)}
                >
                  +
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    cursor: "pointer",
                    // backgroundColor: "#FAFAFA",
                    borderRadius: "5px",
                    height: "20px",
                    color: "#084F8C",
                    fontFamily: "Cairo",
                    backgroundColor: "rgba(250, 250, 250, 1)",
                    width: "20px",
                    textAlign: "center",
                  }}
                >
                  {data?.quantities[0]?.service_quantity}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    cursor: "pointer",
                    fontWeight: "500",
                    lineHeight: "1",
                    color: "rgba(8, 79, 140, 1)",
                    width: "20px",
                    fontFamily: "Cairo",

                    textAlign: "center",
                  }}
                  onClick={() => decrementQuantity(data)}
                >
                  -
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={2}>
            <Box
              fontSize="small"
              sx={{
                width: { sm: "20px !important", xs: "20px !important" },
                // fontSize: { xs: "15px" },
                // paddingRight: "15px",
                fontWeight: "bold",
                padding:
                  selectedLanguage === "ar"
                    ? {
                        xs: "0px 15px 0px 15px",
                        sm: "0px 5px 0px 0px !important",
                      }
                    : {
                        xs: "0px 15px 0px 15px",
                        sm: "0px 0px 0px 5px !important",
                      },
                height: "20px !important",
              }}
            >
              <img
                src={deleteicon}
                style={{
                  // objectFit: "cover",
                  cursor: "pointer",
                  width: "100%",
                  height: "100%",
                  fontWeight: "bold",
                }}
                onClick={() => handleDeleteService(data)}
              />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
export default SummaryCard;
