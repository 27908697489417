import React, { useState, useRef, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import location from "../../assets/location.svg";

const Dropdown = ({
  text,
  type,
  onClick,
  icon,
  selectedLanguage,
  width,
  options,
  selectedLocation,
  setSelectedLocation,
  selectedLocationId,
  setSelectedLocationId,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();
  const getLanguage = sessionStorage.getItem("language");
  const [expanded, setExpanded] = useState(false);
  const inputRef = useRef(null);
 
  const handleExpand = () => {
    setExpanded(!expanded);
  };


  const handleClick = (event) => {
    event.preventDefault(); // Prevent the default focus behavior
    if (!expanded) {
      inputRef.current?.blur(); // Remove focus from the input
    }
  };
  const handleSelectionChange = (event, value) => {
   
    const selectedLocationName = value;
    const selectedLocationIDD = options.find(
      (option) => option.area === selectedLocationName
    )?.id;
    setSelectedLocation(selectedLocationName);
    setSelectedLocationId(selectedLocationIDD);
  };
  useEffect(() => {
    sessionStorage.setItem("selectedLocation", selectedLocation);
    sessionStorage.setItem("selectedLocationId", selectedLocationId);
  }, [selectedLocation, selectedLocationId]);

  const theme = createTheme({
    // direction: selectedLanguage === "ar" ? "rtl" : "ltr", // Both here and <body dir="rtl">
  });

  const renderInput = (params) => {
    const { InputProps, ...rest } = params;
    const isRTL = i18n.dir() === "rtl"; // Check if direction is RTL
    const startAdornmentMargin = isRTL ? "0 8px 20px 0" : "0 0 20px 8px"; // Adjust margin based on direction
    const shouldSwitchPlacement = selectedLanguage === "ar";
    const inputDirection = shouldSwitchPlacement ? "rtl" : "ltr";

    return (
      <TextField
        className={getLanguage === "ar" ? "textfield-1" : "textfield-2"}
        {...rest}
        label={t("areaofResidence")}
        variant="filled"
        sx={{
          marginLeft: "0px !important",
          color: "#ADB4C0 !important",
          borderRadius: "13px",
          fontSize: "14px !important",
          fontFamily: "Cairo",
          color: params.disabled ? "red" : undefined,
          direction: getLanguage === "ar" ? "ltr" : "ltr",
        }}
        inputRef={inputRef}
        onFocus={(event) => {
          event.target.blur(); // Prevent the input from being focused
        }}
        InputProps={{
          ...InputProps,

          startAdornment: (
            <>
              {/* {InputProps.startAdornment} */}
              {!shouldSwitchPlacement && (
                <img
                  src={location}
                  className={
                    getLanguage === "ar" ? "img-select" : "img-select2"
                  }
                  alt="Location Icon"
                  style={{
                    // height: "20px",
                    // width: "20px",
                    margin: startAdornmentMargin,
                    color: "#084F8C",
                    pointerEvents: "none",
                    // "& .MuiInputBase-root.Mui-disabled": {
                    //   color: "red",
                    // },
                    // color: params.disabled ? "red" : undefined,
                  }}
                />
              )}
            </>
          ),
          disableUnderline: true,
        }}
      />
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="autoSelect">
        <Autocomplete
          disablePortal
          // options={[t("alGhubrah"), t("alGhubrah"), t("alGhubrah")]}

          // options={options?.area}
          options={options.map((option) => option.area)}
          renderInput={renderInput}
          renderOption={(props, option) => (
            <li {...props}>
              <Box display="flex" alignItems="center">
                <div style={{ fontSize: "12px" }}>{option}</div>
              </Box>
            </li>
          )}
          getOptionSelected={(option, value) => option === value}
          onOpen={() => setExpanded(true)}
          onClose={() => setExpanded(false)}
          fullWidth
          PaperProps={{
            style: {
              boxShadow: "none",
              borderRadius: "4px",
            },
          }}
          sx={{
            backgroundColor: "white",
            fontFamily: "Cairo",

            width: { width },
            color: "#2D2C30",
            fontFamily: "Cairo",
            fontWeight: "bold",
            fontSize: "16px",

            "& .MuiInputAdornment-root.Mui-end": {
              transform: expanded ? "rotate(0)" : "rotate(180deg)",
            },
            // direction: getLanguage === "arabic" ? "ltr !important" : "",
          }}
          className={`${getLanguage == "ar" ? "ar-lang" : "eng-lang"}`}
          value={selectedLocation}
          onChange={handleSelectionChange}
          onClick={handleClick}
        />
      </div>
    </ThemeProvider>
  );
};

export default Dropdown;
