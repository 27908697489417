import {
  Box,
  Dialog,
  Stack,
  Typography,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import CommonButton from "./CommonButton";
import OptImae from "../../assets/otp1.svg";
import { tabs } from "../../App";
import { Button } from "@mui/base";
import { useTranslation } from "react-i18next";

const OtpModal = ({
  open,
  handleClose,
  selectedTab,
  setSelectedTab,
  setOtpConfirmed,
  selectedLanguage,
  otpConfirmed,
  setVerifyOtp,
  verifyOtp,
  handleClosee,
}) => {
  const [otp, setOtp] = useState("");
  const { t } = useTranslation();

  const otpHandler = () => {};

  const handleClick = () => {
    setOtpConfirmed(false);
  };
// useEffect(()=>{
//   navigator.clipboard.readText()
//   .then(pastedData => {
//     setVerifyOtp(pastedData); // Update the OTP state with the pasted value
//   })
//   .catch(error => {
//     console.error('Failed to read clipboard: ', error);
//   });
// },[])

const handleOtp=(value)=>{
  setVerifyOtp(value)
}
  // const handlePaste = (value) => {
  //   const pastedData=value.clipboardData.getData('Text')
  //   setVerifyOtp(pastedData)


   
  //   };
  return (
    <Dialog
      maxWidth="lg"
      // fullWidth
      open={open}
      className="herreee"
      onClose={handleClosee}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        sx: {
          maxWidth: "386px !important",
          backgroundColor: "rgba(250, 250, 250, 1)",
          borderRadius: "13px",
          paddingY: "25px",
          paddingX: "10px",
          margin: "0px !important",
          overflow: "hidden",
          position: "absolute",
        },
      }}
    >
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Box
          alignItems="center"
          justifyContent="center"
          sx={{ padding: "20px 130px 0px 130px" }}
        >
          <div
            style={{
              width: "80px",
              height: "90px",
              overflow: "hidden",
            }}
          >
            <img
              src={OptImae}
              alt="img"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        </Box>

        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            fontFamily: "Cairo",
            letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
          }}
        >
          {t("enterOtp")}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: "rgba(192, 191, 193, 1)",
            textAlign: "center",
            fontFamily: "Cairo",
            letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
          }}
        >
          {t("enterDigit")} <br /> {t("yourPNumber")}
        </Typography>
        <div className="direction-ltr">
          <OtpInput
            value={verifyOtp}
            onChange={handleOtp}
            numInputs={4}
            isInputNum
            maxLength={4}
            id={'ii'}
            inputMode="numeric"
            inputStyle={{
              width: "62px",
              height: "62px",
              border: "none",
              borderRadius: "16px",
              backgroundColor: "rgba(217, 217, 217, 0.21)",
              fontSize: "32px",
              fontWeight: "700",
              textAlign: "center",
              margin: "0 8px",
            }}
            renderInput={(props) => <input id="inputt" {...props} type="tel" inputMode="numeric" pattern="[0-9]*"  
            // onPaste={handlePaste}
             maxLength={4}/>}
          />
        </div>

        <Button
          variant="contained"
          style={{
            width: "70%",
            borderRadius: "25px",
            color: "white",
            backgroundColor: "#084F8C",
            padding: "10px",
            border: "none",
            cursor: "pointer",
            fontFamily: "Cairo",
            letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
            fontSize: "12px",

            fontWeight: "700",
            margin: "20px 0px",
          }}
          onClick={handleClose}
        >
          {t("confirm")}
        </Button>
      </Stack>
    </Dialog>
  );
};

export default OtpModal;
