import {
  Alert,
  FilledInput,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
// import livingRoom from "../assets/livingroom.png";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
const InputField = ({
  label,
  value,
  icon: Icon,

  placeholder,
  handleChange,
  setState,
  image,
  selectedLanguage,
  includeCountryCode,
  includeEmail,
  includeEmail2
}) => {
  const [isEmailValid, setIsEmailValid] = useState(true); // Email validation state

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [hasInput, setHasInput] = useState(value.trim() !== "");
  const [isShrinked, setIsShrinked] = useState(false);
  const [isFocused, setIsFocused] = useState(false);


  function arabicToEnglish(contactNumber) {
    const arabicNumbers = "٠١٢٣٤٥٦٧٨٩";
    return contactNumber.replace(/[\u0660-\u0669]/g, (d) =>
      arabicNumbers.indexOf(d)
    );
  }

  const handleInputFocus = () => {
    setIsFocused(true);
    setIsShrinked(true); // Set isShrinked to true when the input gains focus
    setHasInput(value.trim() !== ""); // Update the hasInput state based on whether the input has value or not
  };
  const handleInputChange = (event) => {
    const { value } = event.target;
  

    if (label === "Your Email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailRegex.test(value);
      setIsEmailValid(isValidEmail);
    }
    
    if ( includeCountryCode) {
      // alert('ppp')
      // Remove any non-numeric characters from the value
      const numericValue = value.replace(/\D/g, "");

      // Take only the remaining digits (8 digits)
      // const contactNumber = numericValue.slice(0, 8);

      // Combine the country code and contact number for display purposes
      
      
      const arabicNumber = "٩٦٢٥٠٦٤٠";
      const englishNumber = arabicToEnglish(value);
      console.log(englishNumber,"ppppp")
      // alert(englishNumber); 
      setState(englishNumber)
    }

else{
  setState(value);

}
    // alert(value)
    setIsShrinked(true); // Set isShrinked to true when the input value changes
    setHasInput(value.trim() !== ""); // Update the hasInput state based on whether the input has value or not
  };
  const handleInputBlur = () => {
    setIsFocused(false);
    setIsShrinked(value.trim() !== ""); // Set isShrinked based on whether the input has value when it loses focus
  };
  return (
    <div style={{ height: "60px", marginBottom: "10px" }}>
      <FormControl fullWidth sx={{ marginY: "3px" }} variant="filled">
        <InputLabel
          htmlFor="filled-adornment-amount"
          className={selectedLanguage === "ar" ? "input-label1" : "input-label"}
          shrink={hasInput || isShrinked}
          sx={{
            // marginLeft: selectedLanguage === "ar" ? "30px" : "50px",

            // right: { xs: 0 },
            // padding: "6px 0px",

            // marginRight: selectedLanguage === "ar" ? "10px" : "0px",
            // fontSize: hasInput ? "15px" : "14px",
            lineHeight: "1",
            letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
            color: "#ADB4C0 !important",
            marginRight: selectedLanguage==="ar" && !isFocused && !isShrinked?"10px":"",
            // right:selectedLanguage==="ar"  && !includeEmail && isShrinked?"53px !important":selectedLanguage==="ar"  && includeEmail && !isFocused && isShrinked?"35px !important":selectedLanguage==="ar"  && includeEmail && isFocused && isShrinked?"45px !important":selectedLanguage==="ar" && isFocused && isShrinked?"50px !important":""
            right: selectedLanguage==="ar" && isShrinked && includeEmail ? "46px !important":selectedLanguage==="ar" && isShrinked && includeEmail2 ? "38px !important":""
          }}
        >
          {label}
        </InputLabel>
        <FilledInput
          className={
            includeCountryCode && selectedLanguage !== "ar"
              ? "input-fieldd3 "
              : includeCountryCode && selectedLanguage === "ar"
              ? "input-fielddar"
              : "input-fieldd"
          }
          type={includeCountryCode ? "tel" : "text"}
          pattern={includeCountryCode ? "[0-9]*":""}
          id="filled-adornment-amount"
          disableUnderline
          value={value}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          inputProps={{
            maxLength: (includeCountryCode) ? 8 : undefined,
            className: "custom-cursor", // Add the custom CSS class to the input
          }}
          sx={{
            backgroundColor: "white",
            borderRadius: "13px",
            paddingLeft: "0px !important",

            color: "#2D2C30",
            fontFamily: "Cairo",
            fontWeight: "700",
            fontSize: "13.8px",
            "& input::caret": {
              // Adjust the height of the cursor as desired
              height: "10px",
            },
            "&::before": {
              content: `'${
                isShrinked && includeCountryCode && isFocused
                  ? "+968"
                  : isShrinked && includeCountryCode && hasInput
                  ? "+968"
                  : isShrinked && includeCountryCode
                  ? "+968"
                  : includeCountryCode && hasInput
                  ? "+968"
                  : ""
              }'`,
           
              // display: "block",
              // margin: "0 auto",
              // color: "#2D2C30",
              // fontWeight: 700,
              // pointerEvents: "none",
              // textAlign: "center",
              // left:"50%"

              position: "absolute",
              top:
                selectedLanguage === "ar" && !isMobile
                  ? "60.5%"
                  : selectedLanguage === "ar" && isMobile
                  ? "59.87%"
                  : "60%",

                  left:
                  selectedLanguage === "ar"
                    ? "unset"
                    : selectedLanguage === "ar" && isMobile
                    ? "unset"
                    : "59px",
                    right:
                    selectedLanguage === "ar"
                      ? "59px"
                      : selectedLanguage === "ar" && isMobile
                      ? "30px"
                      : "unset",
             transform:"translateY(-50%)",
              direction: "ltr",
              color: "#2D2C30",
              fontWeight: "700",
           

              pointerEvents: "none",
            },
          }}
          startAdornment={
            <InputAdornment
              // position="start"
              sx={{
                margin: 0,
                fontSize: "30px",
                // paddingRight: "10px",
                paddingBottom: "17px",
                color: "#084F8C",
                // marginLeft:selectedLanguage==="ar"?"7px":""
              }}
            >
              {/* <img src="livingRoom" /> */}
              {/* <Icon className="text-icon" fontSize="30px" fontWeight="light" /> */}
              <img
                src={image}
                className={
                  selectedLanguage === "ar" ? "text-icon2" : "text-icon"
                }
              />
            </InputAdornment>
          }
        />
      </FormControl>
      {/* {!isEmailValid && (
        <Typography variant="subtitle2" color="error" sx={{}}>
          Invalid email address
        </Typography>
      )} */}
    </div>
  );
};

export default InputField;
