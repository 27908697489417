export const english = {
  greeting: "Hello, world!",
  welcome: "Welcome to my app.",
  subTotal: "Sub-Total",
  extra: "Extras",
  vat: "VAT",
  total: "Total",
  summary: "SUMMARY",
  selectServices: "SELECT SERVICES",
  selectDateTime: "SELECT DATE & TIME",
  step: "Step",
  estimatedTime: "Estimated Time",
  enteryourDetail: "ENTER YOUR DETAILS",
  enteryourDetail1: "ENTER DETAILS",

  of: "of",
  reviewBooking: "REVIEW BOOKING",
  proceedToPay: "PROCEED TO PAY",
  enterDetails: "ENTER DETAILS",
  chooseTime: "Choose Time",
  assistanceText:
    "Click on a date to see a timeline of available slots, click on the preferred time slot to book it",
  forAssistance: "For Assistance",
  verifyWithOtp: "Verify with OTP",
  yourNumber: "YOUR NUMBER",
  yourName: "YOUR NAME",
  secondaryNumber: "SECONDARY NUMBER (Optional)",
  yourEmail: "YOUR EMAIL (Optional)",
  yourComment: "ANY COMMENT / INSTRUCTIONS",
  enterOtp: "ENTER OTP",
  enterDigit: "Please enter 4-Digit OTP sent to",
  yourPNumber: "your primary number",
  confirm: "CONFIRM",
  bookingConfirm: "BOOKING CONFIRMATION",
  booked: "Booking Confirmed",
  saveBooking: "SAVE BOOKING",
  createBooking: "CREATE BOOKING",
  orderId: "ORDER ID",
  reciept: "RECIEPT",
  serviceName: "{{name}}",
  areaofResidence: "Area of Residence",
  reviewSelection: "REVIEW SELECTION",
  alGhubrah: "Al Ghubrah",
  chooseTimeFor: "Choose Time For",
  noSlots: "No available time slots for the selected date",
  createBooking2: "CREATE NEW BOOKING",
  yourkeeper: "Your keeper will arrive",
  between: "between",
  minimumBooking:"Minimum time selection required is",
  mins:"mins",
  clearAll:"Clear All",
  termsConditions:"Terms and Conditions",
  otpVerify:"Number Verified",
  units:"Unit(s)",
  serviceOverview:"Services Overview",
  contentt:"On-Demand Household Services",
  viewDetails:"View Details"
};
