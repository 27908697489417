export const arabic = {
  greeting: "مرحبًا بالعالم!",
  welcome: "مرحبًا بك في تطبيقي.",
  subTotal: "المجموع الفرعي",
  extra: "إضافي",
  vat: "ضريبة القيمة المضافة",
  total: "المجموع",
  summary: "ملخص",
  selectServices: "حدد الخدمة",
  selectDateTime: "حدد التاريخ والوقت",
  step: "خطوة",
  estimatedTime: "الوقت المقدر",
  enteryourDetail: "بيانات الحجز" ,
  enteryourDetail1: "بيانات الحجز",

  of: "من",
  reviewBooking: "مراجعة الحجز",
  proceedToPay: "الدفع",
  enterDetails: "أدخل التفاصيل",
  chooseTime: "حدد الوقت",
  assistanceText:'انقر على التاريخ والوقت المناسب للحجز',
  forAssistance: "للمساعدة",
  verifyWithOtp: "ارسال رمز التأكيد",
  yourNumber: "الرقم",
  yourName: "الإسم",
  secondaryNumber:"الرقم الثانوي (اختياري)"
  ,  yourEmail: "البريد الالكتروني (اختياري)",
  yourComment: "التعليمات (اختياري) "
   , enterOtp: "ادخل رمز الـOTP",
  // enterDigit: "الرجاء إدخال 4-DIGIT OTP المرسل إلى",
  enterDigit:"الرجاء ادخال الرمز المرسل إليك",
  // yourPNumber: "رقمك الأساسي",
  yourPNumber:"",
  confirm: "تأكيد",
  bookingConfirm:"تأكيد الحجز" ,
  booked: 
  "تم الحجز بنجاح",
  saveBooking: "حفظ الحجز",
  createBooking: "إنشاء الحجز",
  orderId: "رقم الطلب",
  reciept: "إيصال",
  serviceName: "{{name}}",
  areaofResidence: "منطقة الإقامة",
  reviewSelection: "مراجعة التحديد",
  alGhubrah: "الغبرة",
  noSlots: "لا توجد فترات زمنية متاحة للتاريخ المحدد",
  chooseTimeFor: "اختر الوقت",
  createBooking2: "خدمة اخرى",
  yourkeeper: "سنصل اليكم",
  between: " ما بين",
  minimumBooking:"الحد الأدنى لاختيار الوقت المطلوب هو",
  mins:"دقائق",
  clearAll:"امسح الكل",
  termsConditions:"الشروط والأحكام",
  otpVerify:"تم التحقق",
  units:"الوحدات",
  serviceOverview:"تفاصيل الخدمة",
  contentt:"بشكار لخدمات الأعمال المنزلية",
  viewDetails:"تفاصيل الخدمة"


};
