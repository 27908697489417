import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Stack,
  TextareaAutosize,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InputField from "../Common/InputField";
import CommonButton from "../Common/CommonButton";
import OtpModal from "../Common/OtpModal";
import TextArea from "../Common/TextArea";
import VerificationModal from "../Common/VerificationModal";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ChatIcon from "@mui/icons-material/Chat";
import { tabs } from "../../App";
import { useTranslation } from "react-i18next";
import { Select } from "@mui/base";
import Dropdown from "../Common/Select";
import person from "../../assets/person.svg";
import phone from "../../assets/Group.svg";
import msg from "../../assets/msg.svg";
import clipboardCopy from 'clipboard-copy';

// import LocationOnIcon from "@material-ui/icons/LocationOn";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { toast } from "react-toastify";
const CustomerDetails = ({
  selectedTab,
  setSelectedTab,
  openOtpModal,
  handleCloseOtpModal,
  selectedLanguage,
  setOtpConfirmed,
  username,
  email,
  contactNumber,
  secondaryOption,
  setName,
  setEmail,
  setContactNumber,
  setSecondaryOption,
  getOTP,
  orderDetails,
  notes,
  setNotes,
  otp,
  verifyOtp,
  selectedLocationId,
  selectedTime,
  estTime,
  formattedDate,
  bookingAmount,
  bookingParams,
  setVerifyOtp,
  otpConfirmed,
  enablePayment,
  setEnablePayment,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);
  const minimumBookingTime = bookingParams?.[0]?.minimum_booking_time;
  const [phoneNumber, setPhoneNumber] = useState(
    contactNumber ? contactNumber.substring(4) : ""
  );
  const [phoneNumberError, setPhoneNumberError] = useState("");
//  useEffect(()=>{
//   navigator.clipboard.writeText("")

//  },[])
  const handleOpenModal = () => {
    clipboardCopy('')

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      selectedLocationId &&
      username &&
      selectedTime &&
      estTime >= minimumBookingTime &&
      contactNumber.length === 8
    ) {
      toast.success("OTP sent on your primary number");
      setModalOpen(true);
    } else {
      // if (!emailRegex.test(email)) {
      //   toast.error("Please enter a valid email address.");
      // }
      if (username === "") {
        toast.error("Please enter your name.");
      }
      const numberRegex = /^\d+$/;
      if (!numberRegex.test(contactNumber)) {
        toast.error("Please enter a valid contact number.");
      } else if (contactNumber.length > 8) {
        toast.error("Contact number should be 8 digits.");
      } else if (contactNumber.length < 8) {
        toast.error("Contact number should be 8 digits.");
      }
      if (selectedTime === "") {
        toast.error("Please select a time slot");
      }
      // if (username === "") {
      //   toast.error("Name is required");
      // }
      // if (email === "") {
      //   toast.error("Email is required");
      // }

      if (selectedLocationId === "") {
        toast.error("Please select a location");
      }
      if (estTime < minimumBookingTime) {
        toast.error("Estimated time is less than minimum booking time.");
      }
    }

    getOTP();
  };
  const handleCloseModal = (event, reason) => {
   
    setModalOpen(false);

    setEnablePayment(verifyOtp);
    if (verifyOtp !== otp) {
      toast.error("Otp is wrong");
    } else {
      setOtpConfirmed(false);

      orderDetails();
    }
  };
  const handleClosee = (event, reason) => {
    // if (reason && reason == "backdropClick") {
    //   return;
    // }
    setModalOpen(false);
  
  };
 


  const handleClick = () => {
    const url =
      selectedLanguage === "ar"
        ? "https://bishkar.com/terms-conditions"
        : "https://bishkar.com/terms-conditions-ar";
    window.location.href = url;
  };
  return (
    <>
      <Box
        sx={{
          height: isMobile ? "calc(100vh - 380px)" : "410px",
          overflowY: "auto",
          overflowX: "hidden",
          // padding: "0px 9px 0px 9px",
          // overflow: "hidden",
          position: "relative",
          scrollbarColor: "transparent transparent",
          "&::-webkit-scrollbar": {
            display: " none",
          },
        
        }}
      >
        {/* <OtpModal open={openOtpModal} handleClose={handleCloseOtpModal} /> */}
        <Box sx={{ position: "absolute" }}>
          <OtpModal
            open={modalOpen}
            handleClose={handleCloseModal}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            setVerifyOtp={setVerifyOtp}
            verifyOtp={verifyOtp}
            handleClosee={handleClosee}
          />
        </Box>

        <Grid container rowSpacing={1} columnSpacing={1}>
          <Grid item md={6} xs={12}>
            <InputField
              label={t("yourName")}
              selectedLanguage={selectedLanguage}
              icon={PersonOutlineOutlinedIcon}
              value={username}
              setState={setName}
              image={person}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputField
              label={t("yourNumber")}
              selectedLanguage={selectedLanguage}
              icon={WifiCalling3Icon}
              // value={`+987 ${contactNumber}`}
              // setState={setContactNumber}
              value={contactNumber}
              setState={(value) => setContactNumber(value)}
              image={phone}
              includeCountryCode={true}
              countryCodeEditable={true}
            />
          </Grid>
          {!isMobile ? (
            <>
              <Grid item md={6} xs={12}>
                <InputField
                  selectedLanguage={selectedLanguage}
                  label={t("yourEmail")}
                  icon={MailOutlineIcon}
                  value={email}
                  setState={setEmail}
                  image={msg}
                  includeEmail={true}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  selectedLanguage={selectedLanguage}
                  label={t("secondaryNumber")}
                  icon={WifiCalling3Icon}
                  value={secondaryOption}
                  // value={`+987 ${phoneNumber}`}
                  image={phone}
                  setState={(value) => setSecondaryOption(value)}
                  includeCountryCode={true}
                  countryCodeEditable={true}
                  includeEmail2={true}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item md={6} xs={12}>
                <InputField
                  selectedLanguage={selectedLanguage}
                  label={t("secondaryNumber")}
                  icon={WifiCalling3Icon}
                  value={secondaryOption}
                  setState={(value) => setSecondaryOption(value)}
                  includeCountryCode={true}
                  countryCodeEditable={true}
                  image={phone}
                  includeEmail2={true}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  selectedLanguage={selectedLanguage}
                  label={t("yourEmail")}
                  icon={MailOutlineIcon}
                  value={email}
                  setState={setEmail}
                  image={msg}
                  includeEmail={true}
                />
              </Grid>
            </>
          )}

          {!isMobile && (
            <>
              <Grid item>
                <Box></Box>
              </Grid>
              <Grid
                item
                className="verifygrid"
                md={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingBottom: "8px !important",
                }}
              >
                <Box sx={{ margin: "10px 0px 10px 0px" }}>
                  {/* <CommonButton
                    padding={"12px"}
                    text={t("verifyWithOtp")}
                    onClick={handleOpenModal}
                  /> */}
                  <Button
                    disabled={otpConfirmed}
                    style={{
                      color: "white",
                      fontFamily: "Cairo",

                      letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
                      // padding: "14px 20px",
                      padding: "12px 26px",
                      fontSize: "12px",
                      // backgroundColor: "#084F8C",
                      backgroundColor: otpConfirmed
                        ? "#018968"
                        : "#084F8C",

                      borderRadius: "25px",
                      fontWeight: "700",
                      whiteSpace: "nowrap",
                      width: "150px",
                    }}
                    onClick={handleOpenModal}
                  >
                  {otpConfirmed? t("otpVerify"):t("verifyWithOtp")}                    </Button>
                </Box>
              </Grid>
            </>
          )}

          <Grid item md={12} xs={12}>
            <TextArea
              selectedLanguage={selectedLanguage}
              label={t("yourComment")}
              icon={MailOutlineIcon}
              notes={notes}
              setNotes={setNotes}
              // selectedLanguage={selectedLanguage}
            />
          </Grid>
          {isMobile && (
            <>
              <Grid
                item
                md={12}
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="350px"
                marginTop="20px !important"
              >
                <Stack
                  direction="column"
                  position="fixed"
                  bottom="70px"
                  
                  padding="10px 1px 0px 1px"
                  
                  sx={{backgroundColor:"#FAFAFA",zIndex:"10"}}
                >
                  <Button
                    disabled={otpConfirmed}
                    sx={{ padding: "10px" }}
                    style={{
                      color: "white",
                      fontFamily: "Cairo",
                      letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
                      // padding: "14px 20px",
                      // padding: "10px !important",
                      marginTop: "5px",
                      fontSize: "16px",
                      // backgroundColor: "#084F8C",
                      backgroundColor: otpConfirmed
                        ? "#018968"
                        : "#084F8C",

                      borderRadius: "25px",
                      fontWeight: "700",
                      whiteSpace: "nowrap",
                      width: "345px",
                    }}
                    onClick={handleOpenModal}
                  >
                  {otpConfirmed? t("otpVerify"):t("verifyWithOtp")}                
                  </Button>
                  <Box sx={{ marginTop: "5px" ,padding:"10px 0px 35px 0px"}}>
                    <FormControlLabel
                      className={
                        selectedLanguage === "ar"
                          ? "checkbox-ctrl"
                          : "checkbox-ctrl1"
                      }
                      control={
                        <Checkbox
                          defaultChecked
                          sx={{
                            color: "#00DFC9 !important",
                            borderRadius: "6px",
                          }}
                        />
                      }
                      // label="I agree to the terms and conditions"
                      label={
                        // <Typography variant="body1">
                        //   {selectedLanguage === "ar"
                        //     ? I agree to the <strong>Terms and Conditions</strong>
                        //     : ""}
                        // </Typography>
                        <Typography variant="body1" >
                          {selectedLanguage !== "ar" ? "I agree to the " : ""}
                          {selectedLanguage !== "ar" ? (
                            <strong onClick={handleClick} className="terms-hover">Terms and Conditions</strong>
                          ) : (
                            <>
                           <span> أوافق على </span><strong onClick={handleClick} className="terms-hover"> الشروط والأحكام</strong>
                           </>
                          )}
                        </Typography>
                      }
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item md={12} xs={12} sx={{ display: "flex" }}></Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default CustomerDetails;
