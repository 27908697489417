import React, { useEffect, useState } from "react";
import PopupModal from "./components/PopupModal";
import CommonButton from "./components/Common/CommonButton";
import "react-calendar/dist/Calendar.css";
import { createTheme, Stack, ThemeProvider, Typography } from "@mui/material";
import { initReactI18next, useTranslation } from "react-i18next";
import i18n from "i18next";
import { english } from "./locales/english";
import { arabic } from "./locales/arabic";
import axiosInstance from "./api/ApiInstance";
import { useParams, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export const tabs = {
  services: "service",
  dateTime: "dateTime",
  customerDetails: "customerDetails",
  confirmBooking: "confirmBooking",
  reviewBooking: "reviewBooking",
};

const App = () => {
  // const { t } = useTranslation();

  const urlParams = new URLSearchParams(window.location.search);
  const paramValue = urlParams.get("is_success");
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedTab, setSelectedTab] = useState(tabs.services);
  const [selectedServices, setSelectedServices] = useState([]);
  // const [openPopup, setOpenPopup] = useState(!!paramValue || false);
  const [openPopup, setOpenPopup] =useState(true)
  const [selectedLanguage, setSelectedLanguage] = useState(sessionStorage.getItem("language"));
  const [rtl, setRtL] = useState(false);
  // let [searchParams] = useSearchParams();
 

  useEffect(() => {
    if (paramValue === "true") {
      setSelectedTab(tabs?.confirmBooking);
    }
    if (window.history.replaceState) {
      const newUrl = window.location.href.split("?")[0];
      window.history.replaceState({}, "", newUrl);
    }
  }, [paramValue]);

  //  useEffect(() => {
  //   if(selectedLanguage === 'ar'){
  //     window.history.pushState({}, '', '/ar/')
  //     let metaToRemove = document.querySelector('meta[name="description"]');
  //     if(metaToRemove){
  //       metaToRemove.parentNode.removeChild(metaToRemove)
  //     }
  //     let meta = document.createElement('meta')
  //     meta.setAttribute('name', 'description')
  //     meta.setAttribute('content', 'بشكار لخدمات الأعمال المنزلية')
  //     document.head.appendChild(meta)
  //   } else {
  //     window.history.pushState({}, '', '/en/')
  //     let metaToRemove = document.querySelector('meta[name="description"]');
  //     if(metaToRemove){
  //       metaToRemove.parentNode.removeChild(metaToRemove)
  //     }
  //     let meta = document.createElement('meta')
  //     meta.setAttribute('name', 'description')
  //     meta.setAttribute('content', 'On-Demand Household Services')
  //     document.head.appendChild(meta)
  //   }
  // },[selectedLanguage])
  const handleClickOpen = () => {
    setOpenPopup(true);
  };

  const handleClose = () => {
    setOpenPopup(true);
  };

  const addItemToServiceList = (itemName) => {
    const existingItem = selectedServices.find(
      (item) => item.id === itemName.id
    );

    if (existingItem) {
      // Item already exists in the selectedServices array
      const updatedItems = selectedServices.map((item) =>
        item.id === itemName.id
          ? {
              ...item,
              quantities: [
                {
                  service_quantity: Math.min(
                    item.quantities[0].service_quantity + 1,
                    15
                  ),
                },
              ],
            }
          : item
      );
      setSelectedServices(updatedItems);
    } else {
      // Item doesn't exist in the selectedServices array, so add it with quantity 1
      setSelectedServices((prev) => [
        ...prev,
        { ...itemName, quantities: [{ service_quantity: 1 }] },
      ]);
    }
  };
  const theme = createTheme({
    typography: {
      fontFamily: "Cairo",
    },
    direction: "rtl",
  });

  // * Translation service
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: english,
      },
      ar: {
        translation: arabic,
      },
    },
    lng: selectedLanguage, 
    fallbackLng: "en", 
    interpolation: {
      escapeValue: false, 
    },
  });

  return (
    <ThemeProvider theme={theme}>
  {/* {selectedLanguage === 'ar' ? (
          <Helmet>

    <meta name="description" content="بشكار لخدمات الأعمال المنزلية" />
    <meta name="title" content="بشكار لخدمات الأعمال المنزلية" />
    <meta name="og:description" content="بشكار لخدمات الأعمال المنزلية" />
    <meta name="og:title" content="بشكار لخدمات الأعمال المنزلية" />
    </Helmet>
  ) : (
    <Helmet>
    <meta name="description" content="On-Demand Household Services" />
    <meta name="title" content="On-Demand Household Services" />
    <meta name="og:description" content="On-Demand Household Services" />
    <meta name="og:title" content="On-Demand Household Services" />
    </Helmet>
  )} */}
      <div className="app">
        <PopupModal
          handleClose={handleClose}
          open={openPopup}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
          setRtL={setRtL}
          setSelectedLanguage={setSelectedLanguage}
          addItemToServiceList={addItemToServiceList}
          selectedLanguage={selectedLanguage}
        />
        <Stack
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          spacing={1}
        >
          <Typography variant="h6" color="initial">
            Click to book an Appointment
          </Typography>
          <CommonButton onClick={handleClickOpen} text="Book Now" />
        </Stack>
      </div>
    </ThemeProvider>
  );
};

export default App;
