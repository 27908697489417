import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import pexels from "../../assets/blue.jpg";
import { tabs } from "../../App";
import ReceiptCard from "../Receipt/ReceiptCard";
import confirmImage from "../../assets/booked.png";
import MobileDetailsCard from "../Summary/MobileDetailsCard";
import CommonButton from "../Common/CommonButton";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/system";
import DetailsCard from "../Summary/DetailsCard";

import html2canvas from "html2canvas";
import { formatDate } from "../../helpers";

const Booking = ({
  selectedTab,
  setSelectedTab,
 
  setRtL,
  setSelectedLanguage,
 
  selectedLanguage,
  retrievePaymentSession,

  bookingParams,
  bookingAmount,
  setBookingAmount,
 
  sessionId,
  setSessionId,
  estTime,
  setEstTime,
 
  setSelectedServices,
  setSelectedTime,
  setOtpConfirmed,
}) => {

  const orderID = sessionStorage.getItem("bookingId");

  const displayTime = sessionStorage.getItem("bookingParams");
  const selectedTime = sessionStorage.getItem("selectedTime");
  const selectedDate = sessionStorage.getItem("selectedDate");
  useEffect(() => {
    setSessionId(JSON.stringify(sessionStorage.getItem("sessionId")));
    retrievePaymentSession();

  },[]);

  const servicesSelect = JSON.parse(sessionStorage.getItem("servicesss"));
  const [language, setLanguage] = useState(sessionStorage.getItem("language"));
  const { i18n, t } = useTranslation();

  const selectedDeets = JSON.parse(sessionStorage.getItem("orderdeets"));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [inputTime, setInputTime] = useState(""); // State to store the input time
  const [constantValue, setConstantValue] = useState(0); // State to store the constant value (in minutes)
  const [newTime, setNewTime] = useState(""); // State to store the calculated new time

 
  const handleNewClick = () => {
    setSelectedTab(tabs?.services);
    sessionStorage.removeItem("sessionId");
    sessionStorage.removeItem("servicesss");
    sessionStorage.clear();
    setSelectedServices([]);
    setSelectedTime("");
    setBookingAmount("")
    setOtpConfirmed(false);
  };
  useEffect(() => {
    sessionStorage.getItem("language");

    const handleDirection = () => {
      if (language === "ar") {
        i18n.changeLanguage("arabic");

        document.body.dir = "rtl";
        setRtL(true);

        setSelectedLanguage("ar");
      } else {
        document.body.dir = "ltr";
        i18n.changeLanguage("english");
        setRtL(false);

        setSelectedLanguage("en");
      }
    };
    handleDirection();
  }, []);
  
  

  const addMinutesToTime = (time, minutes) => {
    const [hourStr, minuteStr, meridiem] = time.split(/[ :]/);
    const hour = parseInt(hourStr);
    const minute = parseInt(minuteStr);
    const totalMinutes = hour * 60 + minute + minutes;
  
    let newHour = Math.floor(totalMinutes / 60) % 12;
    const newMinute = totalMinutes % 60;
  
    if (newHour === 0) {
      newHour = 12;
    }
  
    let newMeridiem = meridiem;
  
    if (hour === 11 && newHour === 12) {
      newMeridiem = meridiem === "AM" ? "PM" : "AM";
    }
  
    return `${String(newHour).padStart(2, "0")}:${String(newMinute).padStart(2, "0")} ${newMeridiem}`;
  };
  
  
  
  
  
  useEffect(() => {
    if (selectedTime) {
      const timeRange = addMinutesToTime(selectedTime, parseInt(displayTime));
      setNewTime(timeRange);
    }
  }, [selectedTime, displayTime]);
  
  // const getDaySuffix = (day) => {
  //   if (day === 1 || day === 21 || day === 31) {
  //     return "st";
  //   } else if (day === 2 || day === 22) {
  //     return "nd";
  //   } else if (day === 3 || day === 23) {
  //     return "rd";
  //   } else {
  //     return "th";
  //   }
  // };
  const calculateTotalTime1 = () => {
    let totalTime = 0;
    let totalTime1 = 0;
    const buffertime = bookingParams?.[0]?.buffer_time;
    servicesSelect?.map((time) => {
      // totalTime += time.duration;
      totalTime += time?.duration * time?.quantities[0]?.service_quantity;
      // totalTime += buffertime;
    });

    const hours = Math.floor(totalTime / 60);
    const minutes = totalTime % 60;
    const time = hours * 60 + minutes;
    setEstTime(time);

    return `${hours} hours ${minutes} minutes`;
  };
  const calculateSubtotal = () => {
    const subtotal = servicesSelect?.reduce?.(
      (accumulator, service) =>
        accumulator + service.price * service.quantities[0].service_quantity, // Multiply price by quantity
      0
    );

    return subtotal;
  };
  const cost1 = () => {
    const extraCost = bookingParams?.[0]?.extra_cost?.[0];
  
    if (servicesSelect.length > 0) {
      if (extraCost) {
        if (extraCost.onOFF === true || extraCost.onOFF === "true") {
          if (extraCost.cost_type.toLowerCase() === "percentage") {
            return (parseFloat(calculateSubtotal()) * extraCost.cost) / 100;
          } else {
            return extraCost.cost;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };
  // const cost1 = () => {
  //   const extraCost = bookingParams?.[0]?.extra_cost?.[0];

  //   if (extraCost &&  extraCost.onOFF!=="false") {
  //     if (
  //       extraCost.cost_type === "Percentage" ||
  //       extraCost.cost_type === "percentage"
  //     ) {
  //       return (
  //         (parseFloat(calculateSubtotal()) * extraCost.cost) /
  //         100
  //       );
  //     } else if (
  //       extraCost.cost_type !== "Percentage" ||
  //       extraCost.cost_type !== "percentage"
  //     ) {
  //       return extraCost?.cost;
  //     } else if (servicesSelect.length > 0) {
  //       return extraCost.cost;
  //     }
  //   }
  //   return "";
  // };
  // const cost2 = () => {
  //   const extraCost = bookingParams?.[0]?.extra_cost?.[1];

  //   if (extraCost && extraCost.onOFF!=="false") {
  //     if (extraCost.cost_type === "Percentage") {
  //       return (
  //         (parseFloat(calculateSubtotal()) * extraCost.cost) /
  //         100
  //       );
  //     } else if (
  //       extraCost.cost_type !== "Percentage" ||
  //       extraCost.cost_type !== "percentage"
  //     ) {
  //       return extraCost.cost;
  //     } else if (servicesSelect.length < 0) {
  //       return extraCost.cost;
  //     }
  //   }
  //   return "";
  // };
  const cost2 = () => {
    const extraCost = bookingParams?.[0]?.extra_cost?.[1];
  
    if (servicesSelect.length > 0) {
      if (extraCost) {
        if (extraCost.onOFF === true || extraCost.onOFF === "true") {
          if (extraCost.cost_type.toLowerCase() === "percentage") {
            return (parseFloat(calculateSubtotal()) * extraCost.cost) / 100;
          } else {
            return extraCost.cost;
          }
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };
  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal());
    const cost11 = parseFloat(cost1());
    const cost22 = parseFloat(cost2());

    // let total = subtotal + cost11 + cost22;

    // setBookingAmount(total.toFixed(2));
    if (
      bookingParams?.[0]?.extra_cost?.[0].onOFF &&
      bookingParams?.[0]?.extra_cost?.[1].onOFF
    ) {
      let total = subtotal + cost11 + cost22;
      setBookingAmount(total);

      return (subtotal + cost11 + cost22);
    }
    if (
      !bookingParams?.[0]?.extra_cost?.[0].onOFF &&
      !bookingParams?.[0]?.extra_cost?.[1].onOFF
    ) {
      let total = subtotal;
      setBookingAmount(total);

      return (subtotal);
    }
    if (
      !bookingParams?.[0]?.extra_cost?.[0].onOFF &&
      bookingParams?.[0]?.extra_cost?.[1].onOFF
    ) {
      let total = subtotal + cost22;
      setBookingAmount(total);

      return (subtotal + cost22);
    }
    if (
      bookingParams?.[0]?.extra_cost?.[0].onOFF &&
      !bookingParams?.[0]?.extra_cost?.[1].onOFF
    ) {
      let total = subtotal + cost11;
      setBookingAmount(total);

      return (subtotal + cost11);
    }
    if (subtotal === 0 || cost11 === 0 || cost22 === 0) {
      return (0.00);
    }
   
  };
  const handleScreenshot = () => {
    // Capture the screenshot
    html2canvas(document.body).then((canvas) => {
      // Convert the canvas to an image
      const screenshot = canvas.toDataURL();

      // Download the screenshot
      const link = document.createElement("a");
      link.href = screenshot;
      link.download = "screenshot.png";
      link.click();
    });
  };

  return (
    <>
      <Stack
        direction="column"
        alignItems="center"
        sx={{ display: "flex" }}
        justifyContent="space-between"
        spacing={1}
        overflowY="hidden"
        // paddingBottom={2}
      >
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              backgroundColor: "inherit",
              alignText: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "black",
                fontSize: isMobile ? "16px" : "16px",
                fontWeight: 600,
                fontFamily: "Cairo",
                textAlign: "center",
                borderRadius: "25px",
                letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
                padding: "24px 0px 20px 0px",
                // marginBottom: "40px",
              }}
            >
              {t("bookingConfirm")}
            </Typography>
          </Box>

          <Box
            alignItems="center"
            justifyContent="center"
            sx={{
              padding: {
                sm: "45px 130px 7px 130px",
                xs: "5px 130px 7px 130px",
              },
            }}
          >
            <div
              style={{
                width: isMobile ? "50px" : "90px",
                height: isMobile ? "50px" : "90px",
                overflow: "hidden",
              }}
            >
              <img
                src={confirmImage}
                alt="img"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </Box>

          <Typography
            sx={{
              // width: "184px",
              // height: "45px",
              color: "black",
              fontSize: isMobile ? "26px" : "32px",
              fontFamily: "Cairo",

              fontWeight: 700,
              lineHeight: "1.5 !important",
              //   backgroundColor: "rgba(8, 79, 140, 1)",
              borderRadius: "25px",
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
            }}
          >
            {t("booked")}
          </Typography>

          <Typography
            sx={{
              // width: "184px",
              // height: "45px",
              color: "black",
              fontSize: "18px",
              fontFamily: "Cairo",

              fontWeight: 700,
              lineHeight: "1.5 !important",
              //   backgroundColor: "rgba(8, 79, 140, 1)",
              borderRadius: "25px",
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
            }}
          >
            {t("yourkeeper")}
          </Typography>
          <Stack direction="row" > 
          <Typography
            sx={{
              // width: "184px",
              // height: "45px",
              color: "black",
              fontSize: "18px",
              fontFamily: "Cairo",

              fontWeight: 700,
              lineHeight: "1.5 !important",
              //   backgroundColor: "rgba(8, 79, 140, 1)",
              borderRadius: "25px",
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
            }}
          >
            {t("between")} {""}
           
          </Typography>
         <Stack direction="row" sx={{direction:"ltr !important",              lineHeight: "1.5 !important",
margin:"2px 10px"}}>
          <Typography
              style={{
                color: "rgba(8, 79, 140, 1)",
                letterSpacing: "1px",
                fontWeight:700,
                direction: "ltr !important",
              }}
            >
               
            {selectedTime}
            </Typography>
            <Typography
              style={{
                color: "rgba(8, 79, 140, 1)",
                letterSpacing: "1px",
                direction: "ltr !important",
                fontWeight:700,
              }}
            >
               
-            </Typography>
            <Typography
              style={{
                color: "rgba(8, 79, 140, 1)",
                letterSpacing: "1px",
                fontWeight:700,
                direction: "ltr !important",
              }}
            >
                {newTime}
            </Typography>
            </Stack>
          </Stack>

          <Typography
            sx={{
              color: "rgba(45, 44, 48, 1)",
              fontSize: "20px",
              fontFamily: "Cairo",

              fontWeight: 700,
              lineHeight: "1.5 !important",

              // backgroundColor: "rgba(8, 79, 140, 1)",
              borderRadius: "25px",
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
              // marginBottom: "8px",
            }}
          >
            {t("orderId")}: 00{orderID}
            {/* {bookingId} */}
          </Typography>
          <Typography
            sx={{
              color: "rgba(8, 79, 140, 1)",
              fontSize: "16px",
              fontFamily: "Cairo",

              fontWeight: 700,
              lineHeight: "1.5 !important",

              borderRadius: "25px",
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
            }}
          >
            {/* {formatDate1(selectedDeets?.selectedDate)} at{" "}
            {selectedDeets?.selectedTime} */}
            {formatDate(selectedDate)} at {selectedTime}
          </Typography>
          <Typography
            sx={{
              color: "rgba(45, 44, 48, 1)",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "1.5 !important",

              fontFamily: "Cairo",
              borderRadius: "25px",
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
            }}
          >
            {sessionStorage.getItem("selectedLocation")}
          </Typography>
        </Stack>

        {isMobile ? (
          <>
            <Box
              width="100%"
              sx={{
                // background:"red",
                // minHeight:"120px",
                // maxHeight: "120px",
                height:"calc(100vh - 400px )",
                overflowY: "auto",
                marginTop: "10px",
                marginBottom: "20px",
                paddingBottom:"30px",
               
                zIndex: "4",
                backgroundColor: "rgba(250, 250, 250, 1)",
                fontFamily: "Cairo",

                scrollbarColor: "transparent transparent",
                "&::-webkit-scrollbar": {
                  width: "6px",
                  display: "none",
                },
                "&::-webkit-scrollbar-track": {
                  background: "rgba(0, 0, 0, 0.1)",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "rgba(8, 79, 140, 0.5)",
                  borderRadius: "3px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "rgba(8, 79, 140, 0.7)",
                },
                "&::-webkit-scrollbar-thumb:active": {
                  background: "rgba(8, 79, 140, 0.9)",
                },
              }}
            >
              <Grid
                container
                spacing={2}
                marginBottom="30px"
                direction="column"
                backgroundColor="rgba(250, 250, 250, 1)"
                // sx={{maxHeight:"200px",paddingBottom:"30px"}}
              >
                {servicesSelect?.map((service, idx) => {
                  return (
                    <Grid item md={4} sm={2} key={service.idx}>
                      <ReceiptCard data={service} />
                    </Grid>
                  );
                })}
                <Box width="100%" sx={{ paddingLeft:"16px",}}>
              <DetailsCard
                selectedDate={selectedDate}
                servicesSelect={servicesSelect}
                selectedServices={servicesSelect}

                selectedTime={selectedTime}
                selectedLanguage={selectedLanguage}
                bookingParams={bookingParams}
                bookingAmount={bookingAmount}
                setBookingAmount={setBookingAmount}
                estTime={estTime}
                setEstTime={setEstTime}
                calculateTotalTime={calculateTotalTime1}
                calculateSubtotal={calculateSubtotal}
                calculateTotal={calculateTotal}
                cost1={cost1}
                cost2={cost2}
              />
            </Box>
              </Grid>
            </Box>
          </>
        ) : (
          ""
        )}

        {/* <CommonButton text="SAVE BOOKING" /> */}
        {isMobile ? (
          <Stack
            sx={{
              width: "100%",
              position: "fixed",
              bottom: "0",
              zIndex: "7",
              backgroundColor: "rgba(250, 250, 250, 1)",
              marginTop: "20px",
              padding:"20px"
              // overflowY: "auto",
             
            }}
          >
            
            <Stack direction="row" display="flex" spacing={3}>
              <Button
                onClick={handleScreenshot}
                className="margin-b"
                sx={{
                  width: { sm: "60%", xs: "100%" },
                }}
                style={{
                  padding: "10px 10px 10px 10px",
                  color: "white",
                  fontSize: "16px",
                  fontWeight: 700,
                  fontFamily: "Cairo",

                  marginRight: selectedLanguage === "ar" ? "0px" : "10px",
                  backgroundColor: "rgba(8, 79, 140, 1)",
                  borderRadius: "25px",
                  letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
                  whiteSpace: "nowrap",
                }}
              >
                {t("saveBooking")}
              </Button>
              <Button
                className="margin-b"
                onClick={handleNewClick}
                sx={{
                  width: {
                    sm: "60%",
                    xs: "100%",
                    marginLeft: "0px !important",
                  },
                }}
                style={{
                  padding: "10px 10px 10px 10px",
                  color: "white",
                  fontSize: "16px",
                  fontFamily: "Cairo",
                  marginLeft: "0px !important",
                  fontWeight: 700,
                  marginRight: selectedLanguage === "ar" ? "10px" : "0px",
                  backgroundColor: "rgba(0, 223, 201, 1)",
                  borderRadius: "25px",
                  letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
                  whiteSpace: "nowrap",
                }}
              >
                {t("createBooking")}
              </Button>
            </Stack>
          </Stack>
        ) : (
          <>
            <Button
              // onClick={handleSaveClick}
              onClick={handleScreenshot}
              className="bookingbtn-m"
              style={{
                padding: "10px 20px",
                color: "white",
                fontSize: "16px",
                fontFamily: "Cairo",
                fontWeight: 700,
                // margin: "40px 0px 0px 0px",
                backgroundColor: "#084F8C",
                borderRadius: "25px",
                letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
                whiteSpace: "nowrap",
              }}
              sx={{
                // width: "60%",
                width: { sm: "60%", xs: "100%" },
                // margin: { sm: "80px 0px 0px 0px ", xs: "5px 0px 0px 0px" },
              }}
            >
              {t("saveBooking")}
            </Button>
            <Button
              className="margin-b"
              onClick={handleNewClick}
              sx={{
                width: { sm: "60%", xs: "100%" },
              }}
              style={{
                padding: "10px 10px 10px 10px",
                color: "white",
                fontSize: "16px",
                fontFamily: "Cairo",
                fontWeight: 700,
                backgroundColor: "rgba(0, 223, 201, 1)",
                borderRadius: "25px",
                letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
                whiteSpace: "nowrap",
              }}
            >
              {t("createBooking2")}
            </Button>
          </>
        )}
      </Stack>
    </>
  );
};

export default Booking;
