import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://mudeer.bishkar.com",
  //
  //
  // baseURL: "https://108b-119-155-5-216.ngrok-free.app",
  

  headers: {
    "Content-Type": "application/json",
  },
});

export default axiosInstance;
