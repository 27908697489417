import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { servicesList } from "../../utils/data";
import ServiceCard from "./ServiceCard";
import CommonButton from "../Common/CommonButton";
import { useTranslation } from "react-i18next";
import { tabs } from "../../App";
import { motion } from "framer-motion";
import { useTheme } from "@emotion/react";
import { Button } from "@mui/base";
import { toast } from 'react-toastify';
import axiosInstance from "../../api/ApiInstance";

const Services = ({
  allServices,
  setSelectedServices,
  selectedServices,
  setSelectedTab,
  addItemToServiceList,
  selectedLanguage,
  bookingParams,
  setRtL,
  setSelectedLanguage,
  getTimeSlots,
  getLocation,
  calculateSubtotal,
  calculateTotal,
  cost1,
  cost2,
  incrementQuantity,
  decrementQuantity,
  selectedTab,
  
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [language, setLanguage] = useState(sessionStorage.getItem("language"));
  const { i18n, t } = useTranslation();

  const theme = useTheme();
  const sessionLanguage = sessionStorage.getItem("language")
  // if (sessionLanguage===""){
  //   sessionStorage.setItem("language","english")
  // setSelectedLanguage("english")

  // }
  useEffect(() => {
    if (sessionLanguage===""){
      sessionStorage.setItem("language","english")
    setSelectedLanguage("english")
  
    }
    const handleDirection = () => {
      if (language === "ar") {
        i18n.changeLanguage("arabic");

        document.body.dir = "rtl";
        setRtL(true);

        setSelectedLanguage("ar");
      } else {
        document.body.dir = "ltr";
        i18n.changeLanguage("english");
        setRtL(false);

        setSelectedLanguage("en");
      }
    };
    handleDirection();
  }, []);
  useEffect(() => {
    calculateSubtotal();

    cost1();
    cost2();
    calculateTotal();


  }, [selectedServices]);


  return (
    <>
      <Box
        className={selectedLanguage === "ar" ? "one" : "two"}
        sx={{
          // height: isMobile ? "calc(100vh - 200px)" : "410px",
          // marginBottom: isMobile ? "100px" : "",
          paddingBottom:"70px",
          paddingLeft:
            selectedLanguage === "ar" ? "14px !important" : "10px !important",
          paddingRight:
            selectedLanguage === "ar" ? "10px !important" : "14px !important",
// background:"red",
          overflowX: "hidden",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: isMobile ? "0" : "6px",
            display: isMobile ? "none" : "block",
          },
          "&::-webkit-scrollbar-track": {
            background: "rgba(0, 0, 0, 0.1)",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(8, 79, 140, 0.5)",
            borderRadius: "3px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "rgba(8, 79, 140, 0.7)",
          },
          "&::-webkit-scrollbar-thumb:active": {
            background: "rgba(8, 79, 140, 0.9)",
          },
        }}
      >
        
        <Grid
          container
          columnSpacing={3}
          rowSpacing={2}
          display="flex"
          // sx={{ sm:"paddingLeft: "30px", paddingRight: "30px"" }}
          sx={{
            // maxHeight: isMobile ? "calc(100vh - 222px)" : "410px",
            // width: "calc(100% + 13px) !important",
            // maxHeight: isMobile ? "calc(100vh - 222px)" : "410px",

            padding:
              selectedLanguage === "ar" ? "0px 5px 0px 0px" : "0px 0px 0px 5px",
          }}
        >
          
          {allServices?.map((item, idx) => (
            <Grid item xs={6} sm={4} md={4} key={idx}>
              <ServiceCard
                data={item}
                setSelectedServices={setSelectedServices}
                selectedServices={selectedServices}
                addItemToServiceList={addItemToServiceList}
                selectedLanguage={selectedLanguage}
                incrementQuantity={incrementQuantity}
                decrementQuantity={decrementQuantity}
              />
            </Grid>
          ))}
          
        </Grid>
      </Box>
    </>
  );
};

export default Services;
