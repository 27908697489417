import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { tabs } from "../../App";
import CommonButton from "./CommonButton";

import OtpModal from "./OtpModal";
import { useTranslation } from "react-i18next";

const Footer = ({
  selectedTab,
  setSelectedTab,
  selectedLanguage,
  otpConfirmed,
  setOtpConfirmed,
  payCheckout,
  setVerifyOtp,
  portal,
  bookingId,
  estTime,
  bookingParams,
  otp,
  verifyOtp,
  selectedTime,
  enablePayment,
  previousTab,
  setPreviousTab
}) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [isValidTime, setIsvalidTime] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [isOtpValid, setIsOtpValid] = useState(false);
  useEffect(() => {
  
    setIsOtpValid(
      otpConfirmed && enablePayment === verifyOtp && otp.OTP === verifyOtp
    );
  }, [otpConfirmed, otp, verifyOtp, enablePayment]);
  useEffect(() => {
    if (estTime >= bookingParams?.[0]?.minimum_booking_time) {
      setIsvalidTime(true);
    }
    if (estTime < bookingParams?.[0]?.minimum_booking_time) {
      setIsvalidTime(false);
    }
  }, [estTime]);
  const handleCloseModal = () => {
    setModalOpen(false);

  };
  const handleDateTimeButtonClick = () => {
    setSelectedTab(tabs?.dateTime);
  };

  const handleReviewButtonClick = () => {
    setSelectedTab(tabs?.dateTime);
  };

  // const handleDetailsButtonClick = () => {
  //   setSelectedTab(tabs?.customerDetails);
  //   <OtpModal selectedTab={selectedTab} setSelectedTab={setSelectedTab} />;
  // };
  const handleDetailsButtonClick = () => {
    setSelectedTab(tabs?.customerDetails);
  };

  const handlePayButtonClick = () => {
    // setSelectedTab(tabs?.confirmBooking);
    payCheckout();

    // portal();
  };
  return (
    <Grid
      className="footerr"
      container
      sx={{
        height: isMobile ? "90px" : "90px",
        marginTop: "0px !important",
        backgroundColor: "rgba(250, 250, 250, 1)",

        padding: isMobile ? "10px 0px 10px 0px" : "20px 0px 30px 0px",
      }}
    >
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontFamily: "Cairo",

              fontSize: "12px",
              color: "rgba(157, 157, 157, 1)",
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
            }}
          >
            {t("step")} {""}
            {selectedTab === tabs?.services
              ? "1"
              : selectedTab === tabs?.dateTime
              ? "2"
              : selectedTab === tabs?.customerDetails
              ? "3"
              : ""}
            {""} {t("of")} 3
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "12px",
              fontFamily: "Cairo",

              color: "rgba(157, 157, 157, 1)",
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
            }}
          >
            {selectedTab === tabs?.services
              ? t("selectServices")
              : selectedTab === tabs?.dateTime
              ? t("selectDateTime")
              : selectedTab === tabs?.customerDetails
              ? t("enteryourDetail")
              : ""}
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        md={6}
        sm={6}
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {selectedTab === tabs?.services ? (
          <CommonButton
            onClick={handleDateTimeButtonClick}
            text={t("selectDateTime")}
            type="button"
            icon="true"
            disabled={!isValidTime}
            selectedLanguage={selectedLanguage}
          />
        ) : selectedTab === tabs?.dateTime ? (
          <CommonButton
            onClick={handleDetailsButtonClick}
            text={isMobile ? t("enterDetails") : t("enteryourDetail")}
            type="button"
            icon="true"
            disabled={!selectedTime}
            selectedLanguage={selectedLanguage}
          />
        ) : selectedTab === tabs?.customerDetails ? (
          <CommonButton
            onClick={handlePayButtonClick}
            text={t("proceedToPay")}
            type="button"
            icon="true"
            disabled={!isOtpValid}
            selectedLanguage={selectedLanguage}
          />
        ) : selectedTab === tabs?.reviewBooking ? (
          <CommonButton
            onClick={handleReviewButtonClick}
            text={t("enterDetails")}
            type="button"
            icon="true"
            selectedLanguage={selectedLanguage}
          />
        ) : (
          ""
        )}
        {/* <OtpModal
          open={modalOpen}
          handleClose={handleCloseModal}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          selectedLanguage={selectedLanguage}
          otpConfirmed={otpConfirmed} // Pass otpConfirmed state
          setOtpConfirmed={setOtpConfirmed} // Pass setOtpConfirmed function
          setVerifyOtp={setVerifyOtp}
        /> */}
      </Grid>
    </Grid>
  );
};

export default Footer;
