import React from "react";
import { Box, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import SummaryCard from "./SummaryCard";
import DetailsCard from "../Summary/DetailsCard";
import { useTranslation } from "react-i18next";

const Summary = ({
  selectedServices,
  handleDeleteService,
  decrementQuantity,
  incrementQuantity,
  selectedDate,
  selectedTime,
  selectedLanguage,
  bookingParams,
  bookingAmount,
  setBookingAmount,
  estTime,
  setEstTime,
  calculateTotalTime,
  calculateSubtotal,
  calculateTotal,
  cost1,
  cost2,
  selectedTab,
}) => {
  const { t } = useTranslation();
  return (
    <Container sx={{ position: "relative", height: "100%" }} fullWidth>
      <Stack
        direction="column"
        display="flex"
        justifyContent="space-between"
        spacing={2}
        sx={{
          margin: "0px",
          position: "relative",
          height: "100%",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            fontFamily: "Cairo",

            letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
            textAlign: "center",
            height: "40px",
            display: "flex",
            margin: "15px 0px 15px 0px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {t("summary")}
        </Typography>
        <Box
          // className="marginzero1"
          sx={{
            flexGrow: 1,
            // height: "calc(100vh - 600px)",
            height: "200px",
            overflowY: "auto",

            scrollbarWidth: "thin",
            scrollbarColor: "rgba(8, 79, 140, 0.5) rgba(0, 0, 0, 0.1)",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              background: "rgba(0, 0, 0, 0.1)",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(8, 79, 140, 0.5)",
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "rgba(8, 79, 140, 0.7)",
            },
            "&::-webkit-scrollbar-thumb:active": {
              background: "rgba(8, 79, 140, 0.9)",
            },
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            // sx={{ height: "calc(100vh - 733px)" }}
          >
            {selectedServices?.map((item, idx) => (
              <SummaryCard
                key={idx}
                data={item}
                selectedLanguage={selectedLanguage}
                handleDeleteService={handleDeleteService}
                incrementQuantity={incrementQuantity}
                decrementQuantity={decrementQuantity}
              />
            ))}
          </Stack>
        </Box>
        <Box
          sx={{
            // position: "fixed",
            // bottom: "0px",
            marginTop: "0px !important",
          }}
        >
          <DetailsCard
            selectedDate={selectedDate}
            selectedServices={selectedServices}
            selectedTime={selectedTime}
            selectedLanguage={selectedLanguage}
            bookingParams={bookingParams}
            bookingAmount={bookingAmount}
            setBookingAmount={setBookingAmount}
            estTime={estTime}
            setEstTime={setEstTime}
            calculateTotalTime={calculateTotalTime}
            calculateSubtotal={calculateSubtotal}
            calculateTotal={calculateTotal}
            cost1={cost1}
            cost2={cost2}
            selectedTab={selectedTab}
          />
        </Box>
      </Stack>
    </Container>
  );
};
export default Summary;
