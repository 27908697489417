import { Button, useMediaQuery } from "@mui/material";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const CommonButton = ({
  text,
  type,
  onClick,
  icon,
  padding,
  disabled,
  selectedLanguage,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <Button
        variant="contained"
        type={type}
        disabled={disabled}
        onClick={onClick}
        style={{
          color: "white",
          fontFamily: "Cairo",

          letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
          padding: { padding },
          fontSize: "12px",
          fontWeight: "700",
          width: "195px",
          backgroundColor: disabled ? "rgba(205, 205, 205, 1)" : "#084F8C",
          borderRadius: "25px",
          whiteSpace: "nowrap",
        }}
      >
        {text}
        {icon ? (
          <span>
            {selectedLanguage === "ar" ? (
              <ArrowBackIosIcon
                sx={{
                  fontSize: "medium",
                  fontWeight: "bold",
                  marginLeft: "2px",
                  marginTop: "7px",
                  lineHeight: "15px",
                }}
              />
            ) : (
              <ArrowForwardIosIcon
                sx={{
                  fontSize: "medium",
                  fontWeight: "bold",
                  marginLeft: "2px",
                  marginTop: "7px",
                  lineHeight: "15px",
                }}
              />
            )}
          </span>
        ) : null}
      </Button>
    </>
  );
};

export default CommonButton;
