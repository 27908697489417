import React from "react";
import { Box, Button, Stack, useMediaQuery } from "@mui/material";
import SummaryCard from "../Summary/SummaryCard";
import DetailsCard from "../Summary/DetailsCard";
import CommonButton from "./CommonButton";
import { useTheme } from "@emotion/react";
import ReceiptCard from "../Receipt/ReceiptCard";

const ReviewCard = ({
  selectedServices,
  setSelectedServices,
  selectedTime,
  selectedDate,
  selectedLanguage,
  handleDeleteService,
  decrementQuantity,
  incrementQuantity,
  bookingAmount,
  bookingParams,
  setBookingAmount,
  estTime,
  setEstTime,
  calculateTotalTime,
  calculateSubtotal,
  calculateTotal,
  cost1,
  cost2,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const theme = useTheme();
  return (
    <Box>
      <Stack>
        <Box
          sx={{
            flexGrow: 1,
            height: "calc(100vh - 365px)",
            overflowY: "auto",
            marginBottom: "30px",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              background: "rgba(0, 0, 0, 0.1)",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(8, 79, 140, 0.5)",
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "rgba(8, 79, 140, 0.7)",
            },
            "&::-webkit-scrollbar-thumb:active": {
              background: "rgba(8, 79, 140, 0.9)",
            },
            // scrollbarColor: "transparent transparent",
            // scrollbarWidth: "none", // For Firefox
            // "-ms-overflow-style": "none", // For IE and Edge
            // "&::-webkit-scrollbar": {
            //   display: "none", // For Chrome and Safari
            // },
          }}
        >
          {selectedServices?.map((item, idx) => (
            <Box marginBottom={2} key={idx}>
              <ReceiptCard
                data={item}
                handleDeleteService={handleDeleteService}
                decrementQuantity={decrementQuantity}
                incrementQuantity={incrementQuantity}
              />
            </Box>
          ))}
        </Box>

        <Box sx={{ position: "fixed", bottom: "0", width: "93%", flexGrow: 1 }}>
          <DetailsCard
            selectedDate={selectedDate}
            selectedServices={selectedServices}
            selectedTime={selectedTime}
            selectedLanguage={selectedLanguage}
            bookingParams={bookingParams}
            bookingAmount={bookingAmount}
            setBookingAmount={setBookingAmount}
            estTime={estTime}
            calculateTotalTime={calculateTotalTime}
            setEstTime={setEstTime}
            calculateSubtotal={calculateSubtotal}
            calculateTotal={calculateTotal}
            cost1={cost1}
            cost2={cost2}
          />
        </Box>
      </Stack>
    </Box>
  );
};
export default ReviewCard;
