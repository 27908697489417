import React from "react";
import { Chip, useMediaQuery } from "@mui/material";

const Timetags = ({ time, selectedTime, setSelectedTime,setSelectedTimeObj,slots }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  console.log(time,"selected",selectedTime,time,"here",slots)
  const getValueForTime = (time) => {
    
    return slots[time];
  };
  const handleClick = () => {
    setSelectedTime(time);
    const selectedTimeValue = getValueForTime(time);
    setSelectedTimeObj(selectedTimeValue);
   
  };

  return (
    
    <Chip
      key={time}
      label={time}
      variant="outlined"
      onClick={handleClick}
      className="tags-width"
      sx={{
        height: "40px",marginBottom:"9px",
        overflow: "scroll",
        scrollbarColor: "transparent transparent",
        scrollbarWidth: "none",
        border: time === selectedTime ? "none" : "",
        "-ms-overflow-style": "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        borderRadius: "5px",
        backgroundColor:
          time === selectedTime ? "rgba(8, 79, 140, 1)" : "inherit",
        color: time === selectedTime ? "white" : "rgba(8, 79, 140, 1)",
        borderColor: "rgba(8, 79, 140, 1)",
        "&:hover": {
          backgroundColor:
            time === selectedTime
              ? "rgba(8, 79, 140, 1) !important"
              : "inherit",
          cursor: time === selectedTime ? "default" : "pointer",
        },
      }}
    />
  );
};

export default Timetags;
