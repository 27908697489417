import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ReceiptCard from "./ReceiptCard";
import DetailsCard from "../Summary/DetailsCard";
import MobileDetailsCard from "../Summary/MobileDetailsCard";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/system";

const Receiptt = ({
  selectedServices,
  selectedTime,
  // selectedDate,
  selectedLanguage,
  bookingParams,
  bookingAmount,
  setBookingAmount,
  estTime,
  setEstTime,
  calculateTotalTime,

  // calculateTotal,
}) => {
  const [servicesSelect, setServicesSelect] = useState(
    JSON.parse(sessionStorage.getItem("servicesss"))
  );
  const selectedDate = sessionStorage.getItem("selectedDate");

  const calculateTotalTime1 = () => {
    let totalTime = 0;
    let totalTime1 = 0;
    const buffertime = bookingParams?.[0]?.buffer_time;
    servicesSelect?.map((time) => {
      // totalTime += time.duration;
      totalTime += time?.duration * time?.quantities[0]?.service_quantity;
      // totalTime += buffertime;
    });

    const hours = Math.floor(totalTime / 60);
    const minutes = totalTime % 60;
    const time = hours * 60 + minutes;
    setEstTime(time);

    return `${hours} hours ${minutes} minutes`;
  };
  const calculateSubtotal = () => {
    const subtotal = servicesSelect?.reduce?.(
      (accumulator, service) =>
        accumulator + service.price * service.quantities[0].service_quantity, // Multiply price by quantity
      0
    );

    return subtotal;
  };
  const cost1 = () => {
    const extraCost = bookingParams?.[0]?.extra_cost?.[0];

    if (extraCost) {
      if (
        extraCost.cost_type === "Percentage" ||
        extraCost.cost_type === "percentage"
      ) {
        return (
          (parseFloat(calculateSubtotal()) * extraCost.cost) /
          100
        );
      } else if (
        extraCost.cost_type !== "Percentage" ||
        extraCost.cost_type !== "percentage"
      ) {
        return extraCost?.cost;
      } else if (servicesSelect.length > 0) {
        return extraCost.cost;
      }
    }
    return "";
  };
  const cost2 = () => {
    const extraCost = bookingParams?.[0]?.extra_cost?.[1];

    if (extraCost) {
      if (extraCost.cost_type === "Percentage") {
        return (
          (parseFloat(calculateSubtotal()) * extraCost.cost) /
          100
        );
      } else if (
        extraCost.cost_type !== "Percentage" ||
        extraCost.cost_type !== "percentage"
      ) {
        return extraCost.cost;
      } else if (servicesSelect.length < 0) {
        return extraCost.cost;
      }
    }
    return "";
  };
  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal());
    const cost11 = parseFloat(cost1());
    const cost22 = parseFloat(cost2());
    if (
      bookingParams?.[0]?.extra_cost?.[0].onOFF &&
      bookingParams?.[0]?.extra_cost?.[1].onOFF
    ) {
      let total = subtotal + cost11 + cost22;
      setBookingAmount(total);

      return (subtotal + cost11 + cost22);
    }
    if (
      !bookingParams?.[0]?.extra_cost?.[0].onOFF &&
      !bookingParams?.[0]?.extra_cost?.[1].onOFF
    ) {
      let total = subtotal;
      setBookingAmount(total);

      return (subtotal);
    }
    if (
      !bookingParams?.[0]?.extra_cost?.[0].onOFF &&
      bookingParams?.[0]?.extra_cost?.[1].onOFF
    ) {
      let total = subtotal + cost22;
      setBookingAmount(total);

      return (subtotal + cost22);
    }
    if (
      bookingParams?.[0]?.extra_cost?.[0].onOFF &&
      !bookingParams?.[0]?.extra_cost?.[1].onOFF
    ) {
      let total = subtotal + cost11;
      setBookingAmount(total);

      return (subtotal + cost11);
    }
    if (subtotal === 0 || cost11 === 0 || cost22 === 0) {
      return (0.00)
    }
  };
  const estTime1 = calculateTotalTime();
  // const servicesSelect = sessionStorage.getItem("servicesss");
  useEffect(() => {
  }, [servicesSelect]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const addMinutesToTime = (time, minutes) => {
    const [hourStr, minuteStr] = time.split(":");
    const hour = parseInt(hourStr);
    const minute = parseInt(minuteStr);
    const totalMinutes = hour * 60 + minute + minutes;

    const newHour = Math.floor(totalMinutes / 60) % 12 || 12;
    const newMinute = totalMinutes % 60;
    const meridiem = totalMinutes < 720 ? "AM" : "PM";

    return `${newHour}:${String(newMinute).padStart(2, "0")} ${meridiem}`;
  };

  const timeRange = addMinutesToTime(selectedTime, estTime);
  return (
    <Container className="receiptt ">
      <Stack
        direction="column"
        justifyContent="space-between"
        // paddingY={2}
        paddingBottom="8px"
        spacing={2}
        // sx={{ backgroundColor: "green" }}
      >
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontSize: "16px",
              fontWeight: 600,
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
              textAlign: "center",
              marginTop: "25px",
              marginBottom: "20px",
              // marginBottom: "10px",
            }}
          >
            {t("reciept")}
          </Typography>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            height: "298px",
            overflowY: "auto",
            // marginTop: { sm: "100px" },
            // marginBottom: "30px",
            // paddingBottom: "40px",
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(8, 79, 140, 0.5) rgba(0, 0, 0, 0.1)",
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              background: "rgba(0, 0, 0, 0.1)",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "rgba(8, 79, 140, 0.5)",
              borderRadius: "3px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "rgba(8, 79, 140, 0.7)",
            },
            "&::-webkit-scrollbar-thumb:active": {
              background: "rgba(8, 79, 140, 0.9)",
            },
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            sx={{
              height: "calc(100vh - 583px)",
              // overflowY: "auto",
              marginBottom: "10px",
              // scrollbarColor: "transparent transparent",
              // "&::-webkit-scrollbar": {
              //   width: "6px",
              // },
              // "&::-webkit-scrollbar-track": {
              //   background: "rgba(0, 0, 0, 0.1)",
              // },
              // "&::-webkit-scrollbar-thumb": {
              //   background: "rgba(8, 79, 140, 0.5)",
              //   borderRadius: "3px",
              // },
              // "&::-webkit-scrollbar-thumb:hover": {
              //   background: "rgba(8, 79, 140, 0.7)",
              // },
              // "&::-webkit-scrollbar-thumb:active": {
              //   background: "rgba(8, 79, 140, 0.9)",
              // },
            }}
          >
            {servicesSelect?.map?.((services, idx) => {
              return (
                <div key={idx}>
                  <ReceiptCard
                    data={services}
                    selectedLanguage={selectedLanguage}
                  />
                </div>
              );
            })}
          </Stack>
        </Box>
        <Box
          sx={{
            marginTop: "0px !important",
            maxHeight: "200px",
          }}
        >
          <DetailsCard
            selectedDate={selectedDate}
            selectedServices={servicesSelect}
            selectedTime={selectedTime}
            selectedLanguage={selectedLanguage}
            bookingParams={bookingParams}
            bookingAmount={bookingAmount}
            setBookingAmount={setBookingAmount}
            estTime={estTime1}
            calculateTotalTime={calculateTotalTime1}
            setEstTime={setEstTime}
            calculateSubtotal={calculateSubtotal}
            calculateTotal={calculateTotal}
            cost1={cost1}
            cost2={cost2}
          />
        </Box>
      </Stack>
    </Container>
  );
};
export default Receiptt;
