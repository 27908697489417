
import {
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
  TextareaAutosize,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { styled } from "@mui/system";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { makeStyles } from "@mui/styles";
import comments from "../../assets/Comments.svg";
import { useTheme } from "@mui/material/styles";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginTop: "8px",
  },
  inputLabel: {
    marginLeft: theme.direction === "rtl" ? "38px" : 0,
    marginRight: theme.direction === "rtl" ? 0 : "38px",
    textAlign: theme.direction === "rtl" ? "right" : "left",
    fontSize: "12px !important",
    color: "#ADB4C0 !important",
    display: "block",
  },
  textarea: {
    fontFamily: "IBM Plex Sans, sans-serif",
    fontSize: "0.875rem",
    fontWeight: 700,
    lineHeight: 1.5,
    padding: "35px 60px 5px 60px",
    border: "none",
    borderRadius: "13px",
    color: theme.palette.mode === "dark" ? grey[300] : grey[900],
    background: theme.palette.mode === "dark" ? grey[900] : "#fff",
    boxShadow: `0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    }`,
    "&:hover": {
      borderColor: "none",
    },
    "&:focus": {
      borderColor: "none",
      outline: "none",
    },
    "&::placeholder": {
      color: "#afb8c1",
      fontSize: "0.875rem",
      fontWeight: 400,
      paddingLeft: "50px",
    },
  },
  inputAdornment: {
    paddingRight: "10px",
    paddingBottom: "17px",
    position: "absolute",
    top: "25%",
    transform:
      theme.direction === "ltr" ? "translateY(-50%)" : "translateY(-50%)",
  },
}));

function TextArea({ label, selectedLanguage, notes, setNotes }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));


  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleInputChange = (event) => {
    setNotes(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const hasInput = notes.trim() !== ""; // Check if the input has any value

  return (
    <FormControl className={`${classes.formControl} textboxx`}>
      <InputLabel
        htmlFor="comments"
        className={
          selectedLanguage !== "ar" ? "textareainput" : "textareainput2"
        }
        shrink={hasInput}
        sx={{
        
          textAlign: "left",
          // order: selectedLanguage !== "ar" ? "2" : "1",
          color: "#ADB4C0 !important",
          display: "block",
          letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
        }}
      >
        {label}
      </InputLabel>
      {isMobile ? (
        <TextareaAutosize
          style={{ resize: "none" }}
          sx={{ resize: "none" }}
          resize="none"
          aria-label="minimum height"
          className={`${classes.textarea} ${
            selectedLanguage !== "ar" ? "textboxx" : "textboxx2"
          }`}
          onFocus={handleFocus}
          minRows={6}
          maxRows={6}
          id="comments"
          onChange={handleInputChange}
          value={notes}
          inputProps={{
            maxLength: 200,
            style: {
              padding: "0 0 0 45px",
              cursor: "text",
              resize: "none",
            },
          }}
        />
      ) : (
        <TextareaAutosize
          sx={{ resize: "none" }}
          style={{ resize: "none" }}
          resize="none"
          aria-label="minimum height"
          // className={selectedLanguage !== "ar" ? classes.textarea : ""}
          className={`${classes.textarea} ${
            selectedLanguage !== "ar" ? "textboxx" : "textboxx2"
          }`}
          minRows={6}
          // maxRows={6}
          id="comments"
          onChange={handleInputChange}
          value={notes}
          inputProps={{
            maxLength: 300,
            style: {
              padding: "0 60px 0 60px",
              cursor: "text",
              resize: "none",
            },
          }}
        />
      )}

      {/* <InputAdornment
        position="start"
        className={selectedLanguage === "ar" ? classes.inputAdornment : ""}
      >
        <img src={comments} alt="comments-icon" />
      </InputAdornment> */}
      <InputAdornment
        position="start"
        className={
          selectedLanguage === "ar" ? "textareaicon1" : "textareaicon2"
        }
        sx={{
          paddingRight: "10px",
          paddingBottom: "17px",
          position: "absolute",
          // right: "2%",
          top: "25%",
          // transform:
          //   theme.direction === "ltr" ? "translateY(-50%)" : "translateY(-50%)",
        }}
      >
        {/* <ChatOutlinedIcon
          className="textarea-icon"
          sx={{ color: "rgba(8, 79, 140, 1)", fontSize: 30 }}
        /> */}
        <img src={comments} className="textarea-icon" />
      </InputAdornment>
    </FormControl>
  );
}

export default TextArea;
