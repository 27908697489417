


import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { tabs } from "../../App";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import zIndex from "@mui/material/styles/zIndex";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const Header = ({
  selectedTab,
  setSelectedTab,
  setRtL,
  setSelectedLanguage,
  selectedLanguage,
  selectedServices,
  setSelectedServices,
  isServiceSelected,
  setIsServiceSelected,
  otpConfirmed,
  bookingParams,
  previousTab,
  setPreviousTab
}) => {
  const hasSelectedService = selectedServices?.length > 0;
  useEffect(() => {
    setIsServiceSelected(hasSelectedService);
  }, [selectedServices]);

  const { i18n, t } = useTranslation();
  const [language, setLanguage] = useState(sessionStorage.getItem("language"));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const backButtonClick = () => {
    if (selectedTab === tabs?.customerDetails) {
      setPreviousTab(tabs?.customerDetails)
      setSelectedTab(tabs?.dateTime);
    } else if (selectedTab === tabs?.dateTime) {
      setPreviousTab(tabs?.dateTime)
      setSelectedTab(tabs?.services);

    } 
    // else if (selectedTab === tabs?.services) {
    //   setPreviousTab(tabs?.services)
    //   setSelectedTab(tabs?.services);
    // } 
   
    // else if (selectedTab === tabs?.reviewBooking) {
    //   setSelectedTab(tabs.services);
    //   setPreviousTab(null);
    // }
  };

  const crossButtonClick = () => {
    let tabValue = localStorage.getItem('tab')
    setSelectedTab(tabValue)
  };
  const show =
    selectedTab !== tabs?.services && selectedTab !== tabs?.reviewBooking;

  const show1 = selectedTab === tabs?.reviewBooking;

  const handleDirection = () => {
    if (language === "english" || language === null) {
      sessionStorage.setItem("language", "ar");
      i18n.changeLanguage("arabic");

      document.body.dir = "rtl";
      setRtL(false);
      setLanguage("arabic");
      setSelectedLanguage("ar");
    } else {
      sessionStorage.setItem("language", "english");
      document.body.dir = "ltr";
      i18n.changeLanguage("english");
      setRtL(true);
      setSelectedLanguage("english");
      setLanguage("english");
    }
  };

  const getButtonText = () => {
    return language === "english" || language === null ? "Arabic" : "English";
  };

  const minimumBookingTime = bookingParams?.[0]?.minimum_booking_time;

  const isEstimatedTimeValid = () => {
    if (selectedServices?.length === 0) {
      return true; // No services selected, so no need to show the alert
    }
    const estimatedTimeInMinutes = selectedServices?.reduce?.(
      (accumulator, service) =>
        accumulator +
        service.duration * service.quantities[0]?.service_quantity,
      0
    );
    return estimatedTimeInMinutes >= minimumBookingTime;
  };

  const handleReview = (param, next) => {
    localStorage.setItem('tab', param)
    setSelectedTab(next)
  }
  useEffect(() => {
    // Update the previousTab state whenever selectedTab changes
    if (selectedTab !== tabs.services) {
      setPreviousTab(selectedTab);
    }
  }, [selectedTab]);

  return (
    <Stack direction="column">
      <Grid
        container
        sx={{
          height: "70px",
          zIndex: 3,
          backgroundColor: "rgba(250, 250, 250, 1)",
        }}
      >
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            marginTop: "20px",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor: "rgba(250, 250, 250, 1)",
          }}
        >
          {show && (
            <>
              <Box
                onClick={
                  !otpConfirmed
                    ? backButtonClick
                    : otpConfirmed && tabs.confirmBooking
                    ? null
                    : backButtonClick
                }
              >
                {selectedLanguage === "ar" ? (
                  <ArrowForwardIcon />
                ) : (
                  <ArrowBackIcon />
                )}
              </Box>
            </>
          )}

          {show1 && (
            <>
              <Box>
                <CloseOutlinedIcon
                  onClick={crossButtonClick}
                  sx={{ color: "rgba(8, 79, 140, 1)", fontWeight: "600" }}
                />
              </Box>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="rgba(250, 250, 250, 1)"
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
              fontFamily: "Cairo",
              whiteSpace: "nowrap",
            }}
          >
            {selectedTab === tabs?.services
              ? t("selectServices")
              : selectedTab === tabs?.dateTime
              ? t("selectDateTime")
              : selectedTab === tabs?.customerDetails
              ? t("enteryourDetail")
              : selectedTab === tabs?.reviewBooking
              ? t("reviewSelection")
              : ""}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography
            variant="h6"
            style={{
              color: "rgba(8, 79, 140, 1)",
              letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
              fontSize: "16px",
            }}
            sx={{
              md: { fontWeight: 600 },
            }}
          >
            <Button
              sx={{
                border: "none",
                fontSize: "16px",
                fontFamily: "Cairo",
                textTransform: "capitalize",
                color: "rgba(8, 79, 140, 1)",
                letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
                fontWeight: 600,
              }}
              onClick={handleDirection}
            >
              {getButtonText()}
            </Button>
          </Typography>
        </Grid>
      </Grid>
      {isMobile && selectedTab !== tabs?.reviewBooking && (
        <Grid item xs={12} sm={12} md={12}>
          <Stack
            className="reviewbtn"
            display="flex"
            justifyContent="center"
            sx={{ marginBottom: "15px", paddingBottom: "10px", alignItems: "center" }}
          >
            <Button
              style={{
                color: isServiceSelected ? "white" : "rgba(144, 144, 144, 1)",
                fontFamily: "Cairo",
                letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
                border: "none",
                padding: "6px",
                fontWeight: "600",
                backgroundColor: isServiceSelected
                  ? "#084F8C"
                  : "rgba(240, 240, 240, 1)",
                borderRadius: "25px",
                width: "70%",
                fontSize: "16px",
                whiteSpace: "nowrap",
                marginBottom: "0px !important",
              }}
              onClick={()=>handleReview(selectedTab, tabs?.reviewBooking)}
              disabled={!isServiceSelected}
            >
              {t("reviewSelection")}
            </Button>

            {!isEstimatedTimeValid() && (
              <Typography
                color="red"
                sx={{
                  fontSize: selectedLanguage === "ar" ? "12px" : "14px",
                  marginTop: "10px",
                  fontWeight: "bold",
                  textAlign: "center",
                  alignSelf: "center",
                  position: "absolute",
                  top: "105px",
                }}
              >
                {t("minimumBooking")} {bookingParams?.[0]?.minimum_booking_time} {t("mins")}
              </Typography>
            )}
          </Stack>
        </Grid>
      )}
      {isMobile && selectedTab === tabs?.reviewBooking && (
        <Grid item xs={12} sm={12} md={12}>
          <Box
            className="reviewbtn"
            display="flex"
            justifyContent="center"
            sx={{ marginBottom: "15px", paddingBottom: "10px" }}
          >
            <Button
              style={{
                color: "white",
                fontFamily: "Cairo",
                letterSpacing: selectedLanguage !== "ar" ? "1px" : "",
                padding: "6px 30px",
                fontSize: "16px",
                backgroundColor: "#084F8C",
                borderRadius: "25px",
                width: "75%",
                fontWeight: "600",
              }}
              onClick={() => {
                const updatedServices = selectedServices.map((service) => ({
                  ...service,
                  quantities: [{ service_quantity: 0 }],
                }));
                setSelectedServices(updatedServices);
                setSelectedServices([]);
              }}
            >
              {t("clearAll")}
            </Button>
          </Box>
        </Grid>
      )}
    </Stack>
  );
};

export default Header;
